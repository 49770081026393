import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CancelSVG } from '../../assets';
import AuthAxios from '../../axios';
import { ModalContext } from '../../contexts/ModalContext';
import { Album, Track } from '../../types';

import { CustomAlbumComp } from '../common/AlbumContainer';
import IconButton from '../IconButton';

interface IAlbumModalProps {
  selectedTrackList: Track[];
}
export default function AlbumModal(props: IAlbumModalProps) {
  const { t } = useTranslation();
  const { selectedTrackList } = props;
  const { handleModal } = useContext(ModalContext);
  const [albumList, setAlbumList] = useState<Album[]>([]);
  const [open, setOpen] = useState(false);
  const [selectState, setSelectState] = useState(false);
  //const [setSelectedTrackList] = useState<Track[]>([]);
  //const [selectedTrackList, setSelectedTrackList] = useState<Track[]>([]);

  const onClckNewAlbum = () => {
    setOpen(true);
  };
  const handleAddAlbum = () => {
    setOpen(false);
    //앨범 생성과 동시에 선택된 트랙의 아이디 리스트를 서버로 보낼 수 있나?
    // api 호출 필요.
  };

  const handleSubmitNewAlbum = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newAlbumName = e.currentTarget['newAlbumName'].value;
    console.log(newAlbumName);
    AuthAxios.post(`/userAlbum`, { albumName: newAlbumName })
      .then((resolve) => {
        setAlbumList((prev) => [
          ...prev,
          {
            albumId: resolve.data.albumId,
            albumName: newAlbumName,
            createdAt: `${new Date()}`,
            updatedAt: `${new Date()}`,
            total: 0,
            thumbnailList: [],
            tracks: [],
          },
        ]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setOpen(false);
      });
  };
  const onClickAddTrackToAlbum = (album: Album) => {
    //앨범아이디와 선택된 트랙의 아이디 리스트를 서버에 보낸다.

    AuthAxios.post(`/userAlbum/tracks/`, {
      albumId: album.albumId,
      trackIds: selectedTrackList.map((item) => item.id),
    })
      .then((resolve) => {
        console.log(resolve.data);
        // setSelectState(false);
        // selectedTrackList = []
        //setSelectedTrackList([]);
        alert(t('successModal.message'));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        handleModal();
      });
  };
  useEffect(() => {
    AuthAxios.get(`/userAlbum`)
      .then((resolve) => {
        setAlbumList(resolve.data.list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="relative flex flex-col items-center justify-start w-screen h-screen bg-N7 md:w-[560px] md:h-[591px] md:bg-N1 md:rounded-xl p-4 md:p-10">
      <header className="flex items-center justify-between w-full max-md:hidden mb-[39px]">
        <div className=" md:text-N9 md:text-2xl">{t('albumModal.albumModalTitle')}</div>
        <IconButton
          onClick={() => handleModal()}
          className="w-[24px] h-[24px] rounded-full bg-N9 flex justify-center items-center"
        >
          <CancelSVG />
        </IconButton>
      </header>
      <div className="flex items-center justify-between w-full py-3 border-b-N0 md:hidden">
        <div>{t('albumModal.albumModalTitle')}</div>
        <button onClick={() => handleModal()}>{t('albumModal.cancel')}</button>
      </div>
      <div className="flex items-center justify-between w-full mb-5">
        <div>{t('albumModal.albumList')}</div>
        <button
          onClick={onClckNewAlbum}
          className="w-[103px] h-[43px] rounded-md border border-N0 md:border-N9 text-base"
        >
          {t('albumModal.createNewAlbum')}
        </button>
      </div>
      <div className="w-full h-full overflow-y-auto no-scrollbar">
        <main className="flex flex-col gap-4 md:gap-5">
          {albumList.map((item, index) => (
            <div
              onClick={() => onClickAddTrackToAlbum(item)}
              key={`play-list-album-${index}`}
              className="flex items-end justify-start w-full gap-2 md:gap-4 md:items-center"
            >
              <CustomAlbumComp
                album={item}
                className="bg-N3 w-[160px] h-[160px] rounded-[14px]  md:rounded-lg md:w-[80px] md:h-[80px] "
              />
              <div className="flex flex-col ">
                <p className="text-base  text-N1 md:text-N9 md:text-[17px]">{item.albumName}</p>
                <p className="text-sm text-N5 md:text-N8 md:text-[15px]">
                  {item.total}
                  {t('albumModal.track')}
                </p>
              </div>
            </div>
          ))}
        </main>
      </div>

      {open && (
        <div className="absolute top-[30%] bg-N10 w-[328px] md:w-[480px] h-[159px] rounded-[4px] text-N0 p-2 md:p-6 flex flex-col justify-between">
          <header>{t('albumModal.createNewAlbum')}</header>
          <form className="w-full" onSubmit={handleSubmitNewAlbum}>
            <input
              type="text"
              name="newAlbumName"
              className="w-full mb-3 bg-transparent border-b rounded-none text-N0 border-N0"
            />

            <div className="flex justify-end w-full gap-4">
              <button onClick={() => setOpen(false)}>{t('albumModal.createAlbumCancel')}</button>
              <button type="submit">{t('albumModal.createAlbumConfirm')}</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
