import { createContext, useContext, useEffect, useRef, useState } from 'react';

import AuthAxios from '../axios';
import { Theme, Track } from '../types';
import { getItem, setItem, SYSTEMTHEMESAVEINFO } from '../utils/localStorage';
import { transLn } from '../utils/transLn';
import { AuthStateContext } from './AuthContext';
import { SuccessModal } from '../components/modals/ResponseModal';
import { ModalContext } from './ModalContext';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

type MusicPlayerState = {
  currentPlayMusic: Track | null;
  audioRef: React.MutableRefObject<HTMLAudioElement | null> | null;
  backGroundRef: React.MutableRefObject<HTMLAudioElement | null> | null;
  isMusicPlaying: boolean;
  isThemePlaying: boolean;
  isReady: boolean;
  onChangeMusic: (value: Track) => void;
  onStopMusic: () => void;

  onPause: () => void;
  onPlay: () => void;

  // onThemePause: () => void;
  // onThemePlay: () => void;

  duration: number;
  hidePlayer: () => void;
  showPlayer: () => void;

  volume: number;
  backGroundVolume: number;
  togglePlayPause: () => void;

  handleVolumeChange: (volumeValue: number) => void;
  handleBackGroundVolumeChange: (volumeValue: number) => void;
  handleMuteUnmute: () => void;
  handleBackGroundMuteUnmute: () => void;
  handleBackGroundPlayPause: () => void;
  currentProgress: number;
  buffered: number;

  onChangeCurrentProgressBar: (value: number) => void;
  currentTheme: Theme | null;
  onClickTheme: (theme: Theme) => void;
  // setCurrentTheme: React.Dispatch<React.SetStateAction<Theme | null>>;
  themeList: Theme[];

  playList: Track[];
  setPlayList: React.Dispatch<React.SetStateAction<Track[]>>;
  setCurrentPlayMusic: React.Dispatch<React.SetStateAction<Track | null>>;
  currentSongIndex: number;

  rePlayState: number;
  onClickRePlay: () => void;

  shuffleState: boolean;
  onClickShuffle: () => void;

  onClickLikeBtn: () => void;

  onPrev: () => void;
  onNext: () => void;
  onChangePlayListJustAdd: (value: Track[]) => void;
  onChangePlayListOrder: (value: Track[]) => void;
  onChangePlayListOrderShuffle: (value: Track[]) => void;
  onChangePlayListAdd: (value: Track[]) => void;
  onChangePlayListAddOrderShuffle: (value: Track[]) => void;

  handleSetTimer: (second: number) => void;
  setAlarmTime: React.Dispatch<React.SetStateAction<number>>;
};
export const MusicPlayerStateContext = createContext<MusicPlayerState>({
  currentPlayMusic: null,
  audioRef: null,

  backGroundRef: null,
  isMusicPlaying: false,
  isThemePlaying: false,
  isReady: false,

  duration: 0,
  onChangeMusic: () => {},
  onStopMusic: () => {},

  onPause: () => {},
  onPlay: () => {},

  hidePlayer: () => {},
  showPlayer: () => {},

  togglePlayPause: () => {},

  volume: 0.5,
  backGroundVolume: 0.5,
  handleBackGroundVolumeChange: () => {},
  handleVolumeChange: () => {},
  handleMuteUnmute: () => {},
  handleBackGroundMuteUnmute: () => {},
  handleBackGroundPlayPause: () => {},

  currentProgress: 0,
  buffered: 0,

  onChangeCurrentProgressBar: () => {},

  playList: [],
  currentTheme: null,

  onClickTheme: () => {},

  themeList: [],
  setCurrentPlayMusic: () => {},
  setPlayList: () => {},
  currentSongIndex: 1,

  rePlayState: 0,
  onClickRePlay: () => {},

  shuffleState: false,
  onClickShuffle: () => {},

  onClickLikeBtn: () => {},

  onPrev: () => {},
  onNext: () => {},
  onChangePlayListOrder: () => {},
  onChangePlayListOrderShuffle: () => {},
  onChangePlayListAdd: () => {},
  onChangePlayListAddOrderShuffle: () => {},
  onChangePlayListJustAdd: () => {},
  handleSetTimer: () => {},
  setAlarmTime: () => {},
});
export const MusicContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { isAuthentication, user } = useContext(AuthStateContext);
  const [currentPlayMusic, setCurrentPlayMusic] = useState<Track | null>(null);
  const [themeList, setThemeList] = useState<Theme[]>([]);
  const [currentTheme, setCurrentTheme] = useState<Theme | null>(null);
  const [playList, setPlayList] = useState<Track[]>([]);

  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [isdirection, setIsDirection] = useState(false);
  const [isThemePlaying, setIsThemePlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const backGroundRef = useRef<HTMLAudioElement | null>(null);

  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  const [duration, setDuration] = useState(0);
  const [isReady, setIsReady] = useState(true);
  const [volume, setVolume] = useState(0.5);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const { handleModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const [backGroundVolume, setBackGroundVolume] = useState(0.5);

  /** 음원 반복 재생
   * 0:반복재생 안함.
   * 1:해당 곡만 반복재생.
   * 2:플레이리스트 반복재생.
   */
  const [rePlayState, setRePlayState] = useState<0 | 1 | 2>(2);

  /** 플레이리스트 랜덤 재생하기. */
  const [shuffleState, setShuffleState] = useState<boolean>(false);

  /** Music Play/Pause */
  const togglePlayPause = () => {
    if (isMusicPlaying) {
      audioRef.current?.pause();
      setIsMusicPlaying(false);
    } else {
      audioRef.current?.play();
      setIsMusicPlaying(true);
    }
  };

  /** 플레이 리스트에 추가만 하기.*/
  const onChangePlayListJustAdd = (list: Track[]) => {
    //한곡이라도 구독하라고 걸리면 아래는 실행하지 않음.
    if (!user?.currentSubscription && list.some((item) => !item.isFree)) {
      // 멤버쉽가입 페이지로 이동
      window.location.href = '/app/membership';
      return;
    }
    AuthAxios.post(`/playlist/multiple-tracks`, {
      trackIds: list.map((item) => item.id).reverse(),
    })
      .then((resolve) => {
        setPlayList((prev) => {
          // 기존 플레이리스트에 없는 곡만 필터링
          const newTracks = list.filter((newTrack) => !prev.some((prevTrack) => prevTrack.id === newTrack.id));

          // 필터링된 새 곡들을 기존 플레이리스트에 추가
          return [...newTracks, ...prev];
        });
        alert('완료');
        //window.location.reload()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**  현재 재생 목록 비우고 곡 추가하기. */
  const onChangePlayListOrder = async (list: Track[]) => {
    //한곡이라도 구독하라고 걸리면 아래는 실행하지 않음.
    if (!user?.currentSubscription && list.some((item) => !item.isFree)) {
      // 멤버쉽가입 페이지로 이동
      window.location.href = '/app/membership';
      return;
    }
    try {
      const response = await AuthAxios.delete(`/playlist/all`);
      const response2 = await AuthAxios.post(`/playlist/multiple-tracks`, {
        trackIds: list.map((item) => item.id).reverse(),
      });
      const respone3 = await AuthAxios.get(`/playlist`);
      console.log(respone3);
      setPlayList(respone3.data.list);

      //let trackId = Math.floor(Math.random() * (respone3.data.list.length+1 - 0  + 1) + 0);

      //onClickShuffle()

      onChangeMusic(respone3.data.list[0]);
      if (currentPlayMusic) {
        setCurrentSongIndex(list.findIndex((t) => t.id === currentPlayMusic.id));
      }
      alert('완료');
      //window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  /**  현재 재생 목록 비우고 곡 추가하고 랜덤. */
  const onChangePlayListOrderShuffle = async (list: Track[]) => {
    //한곡이라도 구독하라고 걸리면 아래는 실행하지 않음.
    if (!user?.currentSubscription && list.some((item) => !item.isFree)) {
      // 멤버쉽가입 페이지로 이동
      window.location.href = '/app/membership';
      return;
    }
    try {
      const response = await AuthAxios.delete(`/playlist/all`);
      const response2 = await AuthAxios.post(`/playlist/multiple-tracks`, {
        trackIds: list.map((item) => item.id).reverse(),
      });
      const respone3 = await AuthAxios.get(`/playlist`);
      setPlayList(respone3.data.list);

      let trackId = Math.floor(Math.random() * (respone3.data.list.length + 1 - 0 + 1) + 0);

      onClickShuffle();

      onChangeMusic(respone3.data.list[trackId]);
      alert('완료');
      //window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  /** 현재 재생 목록에 곡 추가하기. */
  const onChangePlayListAdd = async (list: Track[]) => {
    //한곡이라도 구독하라고 걸리면 아래는 실행하지 않음.
    if (!user?.currentSubscription && list.some((item) => !item.isFree)) {
      // 멤버쉽가입 페이지로 이동
      window.location.href = '/app/membership';
      return;
    }
    try {
      const response = await AuthAxios.post(`/playlist/multiple-tracks`, {
        trackIds: list.map((item) => item.id).reverse(),
      });
      const respone2 = await AuthAxios.get(`/playlist`);
      setPlayList(respone2.data.list);
      onChangeMusic(respone2.data.list[0]);
      alert('완료');
      //window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  /** 현재 재생 목록에 곡 추가하기. 추가되는 재생 목록은 랜덤하게 */
  const onChangePlayListAddOrderShuffle = async (list: Track[]) => {
    //한곡이라도 구독하라고 걸리면 아래는 실행하지 않음.
    if (!user?.currentSubscription && list.some((item) => !item.isFree)) {
      // 멤버쉽가입 페이지로 이동
      window.location.href = '/app/membership';
      return;
    }
    try {
      const shuffled = list.slice(0).sort(() => Math.random() - 0.5);
      const response = await AuthAxios.post(`/playlist/multiple-tracks`, {
        trackIds: shuffled.map((item) => item.id).reverse(),
      });
      const respone2 = await AuthAxios.get(`/playlist`);
      setPlayList(respone2.data.list);
      onChangeMusic(respone2.data.list[0]);
      alert('완료');
      //window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  /** 다음 곡플레이 */
  /** 다음곡을 직접 선택하는건 현재 곡 반복 재생과는 의미가 없음. 고로 rePlayState를 고려하지 않음. */
  /** 다만 랜덤재생인지 아닌지 그게 중요한것. */
  const onNext = () => {
    setIsDirection(true);
    console.log('rePlayState11', rePlayState, shuffleState);
    //셔플함===랜덤재생함.
    if (shuffleState) {
      //랜덤한 인덱스 위치 추출하기.
      let randomIndex = Math.floor(Math.random() * playList.length);
      console.log('다음곡의 인덱스::', randomIndex);
      console.log('playList::', playList);
      //setCurrentPlayMusic(playList[randomIndex]);

      let currentIndex = playList.findIndex((item) => item.id === currentPlayMusic?.id);
      console.log('현재 재생중인 곡의 인덱스2::', currentSongIndex);
      console.log('현재 재생중인 곡의 인덱스::', currentIndex);
      console.log('다음곡의 인덱스::', currentIndex + 1);

      if (rePlayState === 0) {
        // 반복재생 안함.
        if (playList[currentSongIndex + 1]) {
          setCurrentPlayMusic(playList[randomIndex]);
          setCurrentSongIndex((prev) => prev + 1);
        }
      } else if (rePlayState === 1) {
        // 해당 곡만 반복재생.
        setCurrentPlayMusic(playList[currentIndex]);
      } else if (rePlayState === 2) {
        // 플레이리스트 반복 재생함.
        console.log('onNext', playList.length, currentIndex, currentSongIndex);
        setCurrentPlayMusic(playList[randomIndex]);
      }
    }
    // 셔플 안함===랜덤재생 안함.
    else {
      let currentIndex = playList.findIndex((item) => item.id === currentPlayMusic?.id);

      if (rePlayState === 0) {
        // 반복재생 안함.
        // if (playList[currentSongIndex + 1]) {
        //   setCurrentPlayMusic(playList[currentSongIndex + 1]);
        //   setCurrentSongIndex((prev) => prev + 1);
        // }
        console.log('onNext1', playList.length, currentIndex, currentSongIndex);
        if (playList.length - 1 == currentIndex) {
          setCurrentSongIndex(0);
          setCurrentPlayMusic(playList[0]);
        } else if (currentIndex == 0) {
          setCurrentSongIndex(1);
          setCurrentPlayMusic(playList[1]);
        } else {
          setCurrentPlayMusic(playList[currentSongIndex + 1]);
          setCurrentSongIndex((prev) => prev + 1);
          if (currentSongIndex == playList.length || currentSongIndex == playList.length + 1) {
            console.log('currentSongIndex');
            setCurrentPlayMusic(playList[playList.length - 3]);
          }
        }
        console.log('onNext3', playList.length, currentIndex, currentSongIndex);
      } else if (rePlayState === 1) {
        // 해당 곡만 반복재생.
        setCurrentPlayMusic(playList[currentIndex]);
      } else if (rePlayState === 2) {
        // 플레이리스트 반복 재생함.
        console.log('onNext', playList.length, currentIndex, currentSongIndex);

        if (playList.length - 1 == currentIndex) {
          setCurrentSongIndex(0);
          setCurrentPlayMusic(playList[0]);
        } else {
          setCurrentPlayMusic(playList[currentSongIndex + 1]);
          setCurrentSongIndex(currentSongIndex + 1);
        }
      }
    }
  };
  /** 이전 곡 플레이 */
  const onPrev = () => {
    //셔플함===랜덤재생함.
    setIsDirection(false);
    console.log('rePlayState12', rePlayState, shuffleState);

    if (shuffleState) {
      //랜덤한 인덱스 위치 추출하기.
      let randomIndex = Math.floor(Math.random() * playList.length);
      console.log('다음곡의 인덱스::', randomIndex);
      //setCurrentPlayMusic(playList[randomIndex]);
      //setCurrentSongIndex(randomIndex);
      let currentIndex = playList.findIndex((item) => item.id === currentPlayMusic?.id);

      if (rePlayState === 0) {
        // 반복재생 안함.
        if (playList[currentSongIndex - 1]) {
          setCurrentPlayMusic(playList[randomIndex]);
          setCurrentSongIndex((prev) => prev - 1);
        }
      } else if (rePlayState === 1) {
        // 해당 곡만 반복재생.
        setCurrentPlayMusic(playList[currentIndex]);
      } else if (rePlayState === 2) {
        // 플레이리스트 반복 재생함.
        setCurrentPlayMusic(playList[randomIndex]);
      }
    }
    // 셔플 안함===랜덤재생 안함.
    else {
      let currentIndex = playList.findIndex((item) => item.id === currentPlayMusic?.id);
      console.log('현재 재생중인 곡의 인덱스2::', currentSongIndex);
      console.log('현재 재생중인 곡의 인덱스::', currentIndex);
      console.log('다음곡의 인덱스::', currentIndex - 1);

      console.log('onprev1', playList.length, currentIndex, currentSongIndex);
      if (rePlayState === 0) {
        // 반복재생 안함.
        if (currentIndex == 0) {
          console.log('currentIndex3');
          setCurrentPlayMusic(playList[playList.length - 1]);
          setCurrentSongIndex(playList.length - 1);
        } else if (currentIndex == playList.length - 1) {
          console.log('currentIndex2');
          setCurrentPlayMusic(playList[playList.length - 2]);
          setCurrentSongIndex(playList.length - 2);
        } else {
          console.log('currentIndex3');
          setCurrentPlayMusic(playList[currentSongIndex - 1]);
          setCurrentSongIndex((prev) => prev - 1);
        }

        // setCurrentPlayMusic(playList[currentSongIndex - 1]);
        // setCurrentSongIndex((prev) => prev - 1);
        // if (playList[currentSongIndex - 1]) {
        //   setCurrentPlayMusic(playList[currentSongIndex - 1]);
        //   setCurrentSongIndex((prev) => prev - 1);
        // }
      } else if (rePlayState === 1) {
        // 해당 곡만 반복재생.
        setCurrentPlayMusic(playList[currentIndex]);
      } else if (rePlayState === 2) {
        // 플레이리스트 반복 재생함.
        console.log('onNext', playList.length, currentIndex, currentSongIndex);

        setCurrentPlayMusic(playList[currentSongIndex - 1]);
        setCurrentSongIndex((prev) => prev - 1);

        console.log('aaa', playList.length, currentIndex, currentSongIndex);
      }
    }
  };

  /** 현재 곡이 끝나면.. */
  const onEnded = () => {
    console.log('rePlayState', rePlayState);
    // 만약 현재 rePlayState===0이면 반복 재생 안함. 해당 곡 끝나면 멈춤. 다음곡 버튼을 눌러야 다음곡 재생함.
    if (rePlayState === 0) {
      console.log('끝!');
      return;
    }
    // 만약 현재 rePlayState===1이면 현재 곡만 무한반복.
    else if (rePlayState === 1) {
      console.log('현재곡만 반복재생.');
      if (currentPlayMusic) {
        onPlay();
      }
    }
    // 만약 현재 rePlayState===2이면 플레이리스트 무한반복.
    else if (rePlayState === 2) {
      onNext();

      // let currentIndex = playList.findIndex(
      //   (item) => item.id === currentPlayMusic?.id
      // );
      // console.log("현재 재생중인 곡의 인덱스::", currentIndex);
      // console.log("다음곡의 인덱스::", currentIndex + 1);
      // setCurrentPlayMusic(playList[currentSongIndex + 1]);
      // setCurrentSongIndex((prev) => prev + 1);

      // if( (playList.length-1) == currentIndex+1){
      //   setCurrentSongIndex(-2);
      // }
      // setCurrentPlayMusic(playList[currentSongIndex + 1]);
      // setCurrentSongIndex((prev) => prev + 1);
    }
  };

  /** 볼륨 조절 */
  const handleVolumeChange = (volumeValue: number) => {
    if (!audioRef.current) return;
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);
  };

  /** 배경음악 선택
   * 배경음악을 선택하면 정보를 로컬에 저장하고 저장된 정보를 나중에 불러오기 위해 사용함.
   */
  const onClickTheme = (theme: Theme) => {
    setItem(SYSTEMTHEMESAVEINFO, theme.id);
    setCurrentTheme(theme);
  };

  /** 배경음 볼륨 조절 */
  const handleBackGroundVolumeChange = (volumeValue: number) => {
    if (!backGroundRef.current) return;
    backGroundRef.current.volume = volumeValue;
    setBackGroundVolume(volumeValue);
  };

  /** 뮤트 ON/OFF */
  const handleMuteUnmute = () => {
    if (!audioRef.current) return;
    if (audioRef.current.volume !== 0) {
      audioRef.current.volume = 0;
      // setVolume(0);
    } else {
      audioRef.current.volume = 1;
      // setVolume(1);
    }
  };
  /** 배경음악 볼륨 조절 */
  const handleBackGroundMuteUnmute = () => {
    if (backGroundRef.current?.volume !== 0) {
      backGroundRef.current!.volume = 0;
      // setVolume(0);
    } else {
      backGroundRef.current.volume = 1;
      // setVolume(1);
    }
  };
  /** 배경음악 ON/OFF */
  const handleBackGroundPlayPause = () => {
    if (isThemePlaying) {
      onThemePause();
    } else {
      onThemePlay();
    }
  };

  /** 좋아요 버튼 클릭 */
  const onClickLikeBtn = () => {
    console.log('현재 곡 좋아요', currentPlayMusic?.isLiked);
    // 현재 플레이중인 곡이 있고 해당 곡이 즐겨찾기되어 있는 경우
    if (currentPlayMusic && currentPlayMusic.isLiked === true) {
      AuthAxios.post(`/like/delete/multiple`, {
        trackIds: [currentPlayMusic.id],
      })
        // AuthAxios.delete(`/like?trackId=${currentPlayMusic.id}`)
        .then(() => {
          setCurrentPlayMusic((prev) => {
            return { ...prev!, isLiked: false };
          });
          // 플레이리스트에서도 찾아서 값을 변경.
          setPlayList((prevList) => {
            const updated = prevList.slice(0);
            updated.forEach((item) => {
              if (item.id === currentPlayMusic.id) {
                item.isLiked = false;
              }
            });

            return updated;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (currentPlayMusic && currentPlayMusic.isLiked === false) {
      AuthAxios.post(`/like`, { trackId: currentPlayMusic!.id })
        .then(() => {
          setCurrentPlayMusic((prev) => {
            return { ...prev!, isLiked: true };
          });
          // 플레이리스트에서도 찾아서 값을 변경.
          setPlayList((prevList) => {
            const updated = prevList.slice(0);
            updated.forEach((item) => {
              if (item.id === currentPlayMusic.id) {
                item.isLiked = true;
              }
            });

            return updated;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  const onChangeCurrentProgressBar = (currentValue: number) => {
    setCurrentProgress(currentValue);
  };

  const handleBufferProgress: React.ReactEventHandler<HTMLAudioElement> = (e) => {
    const audio = e.currentTarget;
    const dur = audio.duration;
    if (dur > 0) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
          const bufferedLength = audio.buffered.end(audio.buffered.length - 1 - i);
          setBuffered(bufferedLength);
          break;
        }
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(Math.floor(audioRef.current.duration));
    }
  };

  /** 사운드 클릭 */
  const onChangeMusic = async (data: Track) => {
    try {
      const isLogin = isAuthentication; // 로그인 여부
      const isFree = data.isFree; // 음원 무료 여부
      const isSubscribtion = user?.currentSubscription ?? false; // 구독 여부

      let playMusic = false;
      let addToPlayList = false;
      let redirectToMembership = false;

      if (isFree) {
        if (!isLogin) {
          //const response = await AuthAxios.get(`/track?id=${data.id}`);
          const response = await AuthAxios.get(`/track/${data.id}`);
          setCurrentPlayMusic(response.data);
          playMusic = true;
        } else {
          const response = await AuthAxios.post('/record', {
            trackId: data.id,
          });
          setCurrentPlayMusic(response.data);
          playMusic = true;
          addToPlayList = true;
        }
      } else if (!isFree && isLogin) {
        if (isSubscribtion) {
          const response = await AuthAxios.post('/record', {
            trackId: data.id,
          });
          setCurrentPlayMusic(response.data);
          playMusic = true;
          addToPlayList = true;
        } else {
          redirectToMembership = true;
        }
      } else {
        redirectToMembership = true;
      }

      if (playMusic && audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsMusicPlaying(false);

        onPlay();
      }

      if (addToPlayList) {
        AuthAxios.post(`/playlist`, {
          trackId: data.id,
        })
          .then((resolve) => {
            // setPlayList((prev) => {
            //   const filteredTracks = prev.filter(
            //     (track: Track) => track.id !== data.id
            //   );
            //   return [...filteredTracks, data];
            // });
            setPlayList((prev) => {
              // 플레이리스트에 해당 트랙이 이미 있는지 확인
              const isTrackExists = prev.some((track: Track) => track.id === data.id);

              // 트랙이 이미 존재하지 않는 경우에만 추가
              if (!isTrackExists) {
                return [data, ...prev];
              }

              // 이미 존재하는 경우, 기존 플레이리스트 반환
              return prev;
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (redirectToMembership) {
        window.location.href = '/app/membership';
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsMusicPlaying(false);
    }
  };
  const onPlay = () => {
    //console.log("audioRef.current", audioRef.current)
    let currentIndex = playList.findIndex((item) => item.id === currentPlayMusic?.id);
    console.log('isdirection', isdirection);
    console.log('onPlay', playList.length, currentIndex, currentSongIndex);
    if (currentSongIndex == playList.length) setCurrentSongIndex(playList.length - 1);
    // onprev
    if (!isdirection) {
      if (playList.length - 1 == currentIndex) {
        console.log('last_2');
        setCurrentSongIndex(1);

        if (currentSongIndex == playList.length - 1 || currentSongIndex == playList.length) {
          console.log('last_1_a');
          setCurrentSongIndex(1);
          if (currentSongIndex == playList.length + 1) {
            setCurrentSongIndex(1);
          }
        }
      } else if (currentIndex == 0) {
        console.log('currentIndex=0');
        if (currentSongIndex == -1) setCurrentSongIndex(0);
        setCurrentSongIndex(currentIndex + 1);
      } else {
        setCurrentSongIndex(currentIndex);
      }
    } else {
      if (playList.length - 1 == currentIndex) {
        console.log('last_1_a');
        setCurrentSongIndex(1);
        if (currentSongIndex == playList.length + 1) {
          setCurrentSongIndex(1);
        }
      } else if (currentIndex == 0) {
        if (currentSongIndex == -1) setCurrentSongIndex(0);
        setCurrentSongIndex(1);
        console.log('last_2_a');
      } else {
        setCurrentSongIndex(currentIndex);
      }
    }
    console.log('onPlay_a', playList.length, currentIndex, currentSongIndex);
    if (audioRef.current) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
      setIsMusicPlaying(true);
    }
  };

  const onThemePause = () => {
    if (backGroundRef.current) {
      backGroundRef.current.pause();
      setIsThemePlaying(false);
    }
  };
  const onThemePlay = () => {
    console.log('backGroundRef.current', backGroundRef.current);
    if (backGroundRef.current) {
      const playPromise = backGroundRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
      setIsThemePlaying(true);
    }
  };

  const onStopMusic = () => {
    setIsMusicPlaying(false); // Hide the player

    if (audioRef.current !== null) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;

      audioRef.current.oncanplaythrough = null; // remove the event listener
    }
    setTimeout(() => {
      setCurrentPlayMusic(null);
    }, 600);
  };

  //음원 플레이어 visible
  const showPlayer = () => {
    setIsMusicPlaying(true);
  };

  //음원 플레이어 hidden
  const hidePlayer = () => {
    setIsMusicPlaying(false);
  };

  const [alarmTime, setAlarmTime] = useState(0);
  const [timerActive, setTimerActive] = useState(false);
  const handleSetTimer = (second: number) => {
    console.log('handleSetTimer::', second);
    setAlarmTime(second);
    setTimerActive(true);
  };

  /** 반복 재생 컨트롤러 */
  const onClickRePlay = () => {
    switch (rePlayState) {
      case 0:
        //console.log("반복재생 안함.");
        setRePlayState(1);
        console.log('현재곡만 반복 재생함.');
        break;
      case 1:
        //console.log("해당 곡만 반복재생.");
        setRePlayState(2);
        let currentIndex = playList.findIndex((item) => item.id === currentPlayMusic?.id);

        console.log('플레이리스트 반복 재생함.', playList.length, currentIndex, currentSongIndex);
        if (playList.length - 1 == currentSongIndex) {
          setCurrentSongIndex(1);
        }

        break;
      case 2:
        //console.log("플레이리스트 반복재생.");
        setRePlayState(0);
        console.log('반복 재생 안함.');
        break;
    }
  };
  /** 곡 셔플 컨트롤러 */
  const onClickShuffle = () => {
    //setRePlayState(0);
    console.log('셔플 컨트롤러');
    console.log('현재 재생중인 곡의 인덱스2::', playList.length, currentSongIndex);

    setShuffleState((prev) => !prev);
  };

  const value = {
    currentPlayMusic,
    isMusicPlaying,
    isThemePlaying,
    audioRef,
    backGroundRef,

    onChangeMusic,
    onStopMusic,

    onPause,
    onPlay,

    duration,
    isReady,
    showPlayer,
    hidePlayer,

    togglePlayPause,

    volume,
    backGroundVolume,
    handleVolumeChange,
    handleBackGroundVolumeChange,
    handleMuteUnmute,
    handleBackGroundMuteUnmute,
    handleBackGroundPlayPause,

    currentProgress,
    buffered,
    onChangeCurrentProgressBar,

    currentTheme,

    onClickTheme,
    themeList,
    playList,
    setPlayList,
    setCurrentPlayMusic,
    currentSongIndex,

    rePlayState,
    onClickRePlay,

    shuffleState,
    onClickShuffle,
    onClickLikeBtn,

    onPrev,
    onNext,
    onChangePlayListJustAdd,
    onChangePlayListOrder,
    onChangePlayListOrderShuffle,
    onChangePlayListAdd,
    onChangePlayListAddOrderShuffle,
    handleSetTimer,
    setAlarmTime,
  };
  /** 배경 테마 가져오기. */
  useEffect(() => {
    AuthAxios.get(`/theme/list?page=1&perPage=30`)
      .then((resolve) => {
        console.log(resolve.data.list);
        setThemeList(resolve.data.list);
        let currentThemeId = getItem(SYSTEMTHEMESAVEINFO);
        if (resolve.data.list.some((item: Theme) => item.id === currentThemeId) && currentThemeId) {
          let idx = resolve.data.list.findIndex((item: Theme) => item.id === currentThemeId);
          setCurrentTheme(resolve.data.list[idx]);
        } else {
          setCurrentTheme(resolve.data.list[0]);
        }
        // if (backGroundRef.current) {
        //   console.log("backgroundRef 존재함.");
        //   backGroundRef.current.src = resolve.data.list[0].url;
        //   setTimeout(() => {
        //     if (backGroundRef.current) {
        //       console.log("노래 틀 수 있나?");

        //       backGroundRef.current.play();
        //     }
        //   }, 3000);
        // }
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  /** 곡이 변경되면 자동 재생 */
  useEffect(() => {
    if (currentPlayMusic) {
      onPlay();
    }
  }, [currentPlayMusic?.id]);
  useEffect(() => {
    if (currentTheme) {
      onThemePlay();
    }
  }, [currentTheme?.id]);

  useEffect(() => {
    let interval: any;
    if (timerActive && alarmTime > 0) {
      interval = setInterval(() => {
        setAlarmTime((prev) => prev - 1);
      }, 1000);
    } else if (alarmTime === 0 && timerActive) {
      setTimerActive(false);

      onPause();
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerActive, alarmTime]);

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        // const resolve = await AuthAxios.get("/web/playlist");

        //재생목록 곡을 가져올때 객체전체 덩어리를 가져오는게 맞다.

        AuthAxios.get('/playlist').then((resolve) => {
          setPlayList(resolve.data.list);
        });

        // const tracks = await Promise.all(
        //   resolve.data.trackIds.map(async (item: number) => {
        //     const response = await AuthAxios.get(`/track?id=${item}`);
        //     return response.data;
        //   })
        // );

        // setPlayList(tracks);
      } catch (error) {
        console.error('Failed to fetch playlist:', error);
      }
    };
    if (isAuthentication) {
      //로그인 되면 플레이 리스트 가져오기
      fetchPlaylist();
    } else {
      //로그아웃 또는 로그인이 안돼어 있으면 플레이리스트 비우기
      setPlayList([]);
    }
  }, [isAuthentication]);

  useEffect(() => {
    const handlePlay = () => {
      if (backGroundRef.current) {
        backGroundRef.current.pause();
      }
    };

    const handleEnded = () => {
      if (backGroundRef.current) {
        backGroundRef.current.play();
      }
    };

    if (audioRef?.current) {
      audioRef.current.addEventListener('play', handlePlay);
      audioRef.current.addEventListener('ended', handleEnded);
      audioRef.current.addEventListener('pause', handleEnded);
    }

    // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거합니다.
    return () => {
      if (audioRef?.current) {
        audioRef.current.removeEventListener('play', handlePlay);
        audioRef.current.removeEventListener('ended', handleEnded);
        audioRef.current.removeEventListener('pause', handleEnded); // Here, you should use 'removeEventListener' instead of 'addEventListener'
      }
    };
  }, []);

  //const value = { modal, modalContent, handleModal };

  return (
    <MusicPlayerStateContext.Provider value={value}>
      {/* 배경 사운드 */}
      <audio
        loop={true}
        ref={backGroundRef}
        src={currentTheme?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentTheme.url}`}
        onCanPlay={(e) => {
          e.currentTarget.volume = 0.1;
        }}
        onVolumeChange={(e) => handleBackGroundVolumeChange(e.currentTarget.volume)}
        onPlay={(e) => {
          setIsThemePlaying(true);
        }}
        onPause={(e) => {
          setIsThemePlaying(false);
        }}
      >
        <source
          src={currentTheme?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentTheme.url}`}
          type="audio/mpeg"
        />
      </audio>
      {/* 사용자 플레이 사운드 */}
      <audio
        ref={audioRef}
        src={currentPlayMusic?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.url}`}
        onLoadedMetadata={handleLoadedMetadata}
        onDurationChange={(e) => setDuration(e.currentTarget.duration)}
        onCanPlay={(e) => {
          e.currentTarget.volume = volume;
          setIsReady(true);
        }}
        onTimeUpdate={(e) => {
          setCurrentProgress(e.currentTarget.currentTime);
          handleBufferProgress(e);
        }}
        onPlay={() => {
          setIsMusicPlaying(true);
        }}
        onPause={() => {
          setIsMusicPlaying(false);
        }}
        onEnded={() => {
          // onNext();
          onEnded();
        }}
        onProgress={handleBufferProgress}
        onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
      >
        <source
          src={currentPlayMusic?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.url}`}
          type="audio/mpeg"
        />
        <source
          src={currentPlayMusic?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.url}`}
          type="audio/ogg"
        />
        <source
          src={currentPlayMusic?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.url}`}
          type="audio/mp3"
        />
        <source
          src={currentPlayMusic?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.url}`}
          type="audio/wav"
        />
        <source
          src={currentPlayMusic?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.url}`}
          type="audio/mp4"
        />
        <source
          src={currentPlayMusic?.url && `${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.url}`}
          type="audio/oga"
        />
        <meta itemProp="image" content={currentPlayMusic?.thumbnail} />
        <meta itemProp="name" content={currentPlayMusic?.title ? transLn(currentPlayMusic.title) : ''} />
        <meta itemProp="artist" content="soundPill" />
      </audio>
      {children}
    </MusicPlayerStateContext.Provider>
  );
};
