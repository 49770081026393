import { createContext, Dispatch, SetStateAction, useState } from 'react';
import AuthAxios from '../axios';
import { Coupon } from '../types';

type PromotionContextState = {
  redirectUrl: string;
  couponCode: string;
  isPromotionPageEntered: boolean;
  isCouponRegistered: boolean;
  isCouponUsed: boolean;
  error?: boolean;
  coupon?: Partial<Coupon>;
};
type PromotionContextType = {
  state: PromotionContextState;
  setState: Dispatch<SetStateAction<PromotionContextState>>;
  getCouponInfo: () => Promise<void>;
  registerCouponAndUseCoupon: () => Promise<void>;
};

export const PromotionContext = createContext<PromotionContextType>({} as PromotionContextType);

export const PromotionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<PromotionContextState>({
    redirectUrl: '',
    isPromotionPageEntered: false,
    isCouponRegistered: false,
    isCouponUsed: false,
    couponCode: '',
  });

  const getCouponInfo = async () => {
    try {
      const response = await AuthAxios.get(`/coupon?code${state.couponCode}`);

      if (response && response.status === 200) {
        setState({
          ...state,
          coupon: response.data.coupon,
        });
      } else {
        throw new Error();
      }
    } catch (error: any) {
      setState({
        ...state,
        error: error.response.data,
      });
    }
  };

  const registerCouponAndUseCoupon = async () => {
    try {
      const response1 = await AuthAxios.post(`/coupon/register`, {
        couponCode: state.couponCode,
      });

      if (response1 && response1.status === 200) {
        setState({
          ...state,
          isCouponRegistered: true,
        });
      } else {
        throw new Error();
      }

      const response2 = await AuthAxios.post(`/coupon/use`, {
        couponCode: state.couponCode,
      });

      if (response2 && response2.status === 200) {
        setState({
          ...state,
          isCouponUsed: true,
        });
      } else {
        throw new Error();
      }
    } catch (error: any) {
      setState({
        ...state,
        error: error.response.data,
      });
    }
  };

  return (
    <PromotionContext.Provider value={{ state, setState, getCouponInfo, registerCouponAndUseCoupon }}>
      {children}
    </PromotionContext.Provider>
  );
};
