import Swal from 'sweetalert2';

export const sweetErrorHandling = async (err: any, sweet_off: boolean = false) => {
  if (!sweet_off) {
    await Swal.fire({
      icon: 'error',
      showConfirmButton: false,
      customClass: {
        popup: 'w-full max-w-[90%] md:max-w-[600px]',
      },
    });
  }
};

export const sweetTopSuccessAlert = async (msg: string, duration: number = 2000) => {
  await Swal.fire({
    position: 'top',
    icon: 'success',
    title: msg,
    showConfirmButton: false,
    timer: duration,
    customClass: {
      popup: 'w-full max-w-[90%] md:max-w-[400px]',
    },
  });
};

export const sweetTopSmallSuccessAlert = async (
  msg: string,
  duration: number = 2000,
  enable_forward: boolean = false
) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: duration,
    timerProgressBar: true,
    customClass: {
      popup: 'w-full max-w-[90%] md:max-w-[400px]',
    },
  });

  Toast.fire({
    icon: 'success',
    title: msg,
  }).then((data) => {
    if (enable_forward) {
      window.location.reload();
    }
  });
};

export const sweetFailureProvider = async (
  msg: string,
  show_button: boolean = false,
  enable_forward: boolean = false,
  forward_url: string = '/'
) => {
  await Swal.fire({
    icon: 'error',
    title: msg,
    showConfirmButton: show_button,
    confirmButtonText: 'OK',
    customClass: {
      popup: 'w-full max-w-[90%] md:max-w-[500px]',
    },
  }).then((data) => {
    if (enable_forward) {
      window.location.replace(forward_url);
    }
  });
};
