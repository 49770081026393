import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LOGOSVG } from '../../assets';
import AuthAxios from '../../axios';
import OpenAppBtn from '../../components/common/OpenAppBtn';
import { useTranslation } from 'react-i18next';
import ExploreBtn from '../../components/common/ExploreBtn';
import SocialLoginButtons from '../../components/socialLogin/SocialLoginButtons';

export default function LoginMainPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');

  const [isPasswordError, setPasswordError] = useState(false);
  const [isCheckPasswordError, setCheckPasswordError] = useState(false);

  // const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID; // 발급받은 클라이언트 아이디
  // const REDIRECT_URI = process.env.REACT_APP_CALLBACK_URL; // Callback URL
  // const STATE = "flase";
  // const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

  // const NaverLogin = () => {
  //   window.location.href = NAVER_AUTH_URL;
  // };

  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="flex items-center justify-between w-full px-5 mt-5 md:flex-row-reverse mb-[128px]">
        <LOGOSVG className="md:hidden" width={93} />
        <div className="flex gap-[6.75px]">
          <OpenAppBtn />
          <ExploreBtn />
        </div>
      </div>

      <div className="flex flex-col items-center text-lg font-bold text-N0 leading-[26px] mb-[21px] md:text-xl">
        {/* font pretendard */}
        <LOGOSVG className="max-md:hidden mb-[34px] md:w-[163px]" />
        <h1 className="text-center w-[220px]">{t('web.introText')}</h1>
      </div>

      <form
        className="flex flex-col items-center w-full gap-4 mb-[68px]"
        onSubmit={(e) => {
          e.preventDefault();
          //console.log("email:", email);
          // console.log("password:", password);
          // console.log("checkPassword:", checkPassword);
          if (password != checkPassword) {
            alert('일치하지 않는 비밀번호 입니다.');
            return;
          }
          AuthAxios.post('/auth/register', {
            email,
            password,
            termsAgreed: false,
          })
            .then((resolve) => {
              console.log(resolve.data);
              navigate('/auth/');
            })
            .catch((error) => {
              alert(error.response.data.message);
              console.log('error', error);
            });
        }}
      >
        <input
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder={t('web.emailAddress')}
          className="focus:outline-none w-[288px] h-[48px] rounded-lg p-3 text-base text-N3 focus:placeholder:drop-shadow-md md:w-[360px] md:h-[56px]"
        />
        <input
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          onBlur={(e) => {
            if (e.currentTarget.value.length < 8) {
              setPasswordError(true);
            } else {
              setPasswordError(false);
            }
          }}
          placeholder={t('web.password')}
          className="focus:outline-none w-[288px] h-[48px] rounded-lg p-3 text-base text-N3 focus:placeholder:drop-shadow-md md:w-[360px] md:h-[56px]"
        />
        {isPasswordError && <div style={{ color: 'red' }}>{t('web.passwordLength')}</div>}
        <input
          name="check_password"
          type="password"
          value={checkPassword}
          onChange={(e) => setCheckPassword(e.currentTarget.value)}
          onBlur={(e) => {
            if (e.currentTarget.value.length < 8) {
              setCheckPasswordError(true);
            } else {
              setCheckPasswordError(false);
            }
          }}
          placeholder={t('web.confirmPassword')}
          className="focus:outline-none w-[288px] h-[48px] rounded-lg p-3 text-base text-N3 focus:placeholder:drop-shadow-md md:w-[360px] md:h-[56px]"
        />
        {isCheckPasswordError && <div style={{ color: 'red' }}>{t('web.passwordLength')}</div>}
        <button
          type="submit"
          className="rounded-lg w-[288px] h-[48px] text-N2 leading-6 md:w-[360px] md:h-[56px]"
          style={{
            textAlign: 'center',
            backgroundImage: 'linear-gradient(160deg, #473CBC, #202E67)',
          }}
        >
          {t('web.start')}
        </button>
      </form>

      <div className="w-[288px] h-[18px] flex items-center justify-between mb-[39px] md:w-[360px]">
        <div className="w-[117.33px] h-[1px] bg-N3 md:w-[152px]" />
        {/* font Noto Sans KR */}
        <span className="text-[13px] leading-[18px] text-N3">{t('web.or')}</span>
        <div className="w-[117.33px] h-[1px] bg-N3 md:w-[152px]" />
      </div>

      <div className=" w-[288px] h-[82px]  flex flex-col items-center justify-between mb-[56px]">
        <span className="text-[15px] text-N1 font-medium">{t('web.socialLogin')}</span>
        <div className="flex justify-between w-full h-[48px]">
          <SocialLoginButtons />
          {/* <FacebookLoginBtn />
          <AppleLoginBtn />
          <GoogleLoginBtn />
          <NaverLoginBtn />
          <KakaoLoginBtn /> */}
        </div>
      </div>
      <span className="text-[15px] font-medium text-N1 mb-[92px]">
        {t('web.accountExists')}{' '}
        <Link to={'/auth'} className="underline ">
          {t('web.login')}
        </Link>
      </span>
    </div>
  );
}
