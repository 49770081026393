import { createContext, Dispatch, SetStateAction, useState } from 'react';
import AuthAxios from '../axios';
import { User } from '../types';
import { AxiosError, AxiosResponse } from 'axios';

export type ThirdPartyCode = 'VITAPORT' | 'DBLIFE';

type ThirdPartyContextState = {
  thirdPartyId?: string;
  thirdPartyCode?: ThirdPartyCode;
  thirdPartyUserId?: string;
  orderNumber?: string;
  existingUser?: boolean;
  // couponNoticed: boolean;
  // currentSubscription: boolean;
  // productId: string;
  couponAutoUsed: boolean;
  usedCouponId?: number;
  usedCouponCode?: string;
  usedCouponName?: string;
  error?: boolean;
  message?: string;
  isSurveyFinished: boolean;
  surveyData?: {
    surveyResult?: object; // DB생명 설문 결과
    referer?: string;
  };
  hasNoticed: boolean;
  isVip?: boolean;
};
type ThirdPartyContextType = {
  state: ThirdPartyContextState;
  setState: Dispatch<SetStateAction<ThirdPartyContextState>>;
  createThirdPartyUser: (user: User) => Promise<void>;
  linkUserToThirdPartyUser: (user: User) => Promise<void>;
  autoUseRegisteredOrderedCoupon: (user: User) => Promise<void>;
  autoUseFreeCoupon: (user: User) => Promise<void | {
    error?: boolean;
    message?: string;
    autoUsed?: boolean;
    couponId?: string;
    couponCode?: string;
    couponName?: string;
  }>;
};

export const ThirdPartyContext = createContext<ThirdPartyContextType>({} as ThirdPartyContextType);

export const ThirdPartyContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<ThirdPartyContextState>({
    couponAutoUsed: false,
    error: false,
    isSurveyFinished: false,
    hasNoticed: false,
  });

  const createThirdPartyUser = async (user: User) => {
    const response = await AuthAxios.post(`/third-party/user`, {
      thirdPartyCode: state.thirdPartyCode,
      userId: user.id,
    }).catch((error) => {
      console.error(error);
    });

    setState({
      ...state,
      existingUser: response ? response.data.existingUser : false,
    });
  };

  const linkUserToThirdPartyUser = async (user: User) => {
    const {thirdPartyCode, thirdPartyUserId} = state;
    if (!state.thirdPartyCode) {
      return;
    }

    const response = await AuthAxios.put(`/third-party/user`, {
      thirdPartyCode,
      thirdPartyUserId,
      userId: user.id,
    }).catch((error) => {
      console.error(error);
    });

    setState({
      ...state,
      existingUser: response ? response.data.existingUser : false,
    });
  };

  const autoUseFreeCoupon = async (user: User) => {
    if (!state.thirdPartyCode) {
      return;
    }

    console.log('autoUseCoupon called');

    const response = await AuthAxios.post<
      any,
      AxiosResponse<{
        error?: boolean;
        message?: string;
        autoUsed?: boolean;
        couponId?: string;
        couponCode?: string;
        couponName?: string;
      }>
    >(`/collaboration/coupon/auto-use-free`, {
      collaboratorCode: state.thirdPartyCode,
      userId: user.id,
    }).catch((error: AxiosError<{ message: string }>) => {
      console.log(error.response?.data?.message);
    });

    return response && response.data;
  };

  const autoUseRegisteredOrderedCoupon = async (user: User) => {
    console.log('users: ', user);
    console.log('state: ', state);
    console.log('auto use called');
    if (!state.thirdPartyId || !state.thirdPartyUserId) {
      return;
    }

    if (isNaN(parseInt(state.thirdPartyId))) {
      return;
    }

    console.log('autoUseRegisteredOrderedCoupon called');
    const response = await AuthAxios.put(`/collaboration/coupon/auto-use`, {
      collaboratorId: parseInt(state.thirdPartyId),
      userId: user.id,
      collaborateUserId: state.thirdPartyUserId,
      orderNumber: state.orderNumber,
    }).catch((error) => {
      console.log(error);
    });

    if (response && response.data) {
      setState({
        ...state,
        couponAutoUsed: response.data.autoUsed,
        usedCouponId: response.data.couponId,
        usedCouponCode: response.data.couponCode,
        usedCouponName: response.data.couponName,
        error: response.data.error,
        message: response.data.message,
      });
    }

    // setState({
    //   ...state,
    //   couponAutoUsed: response. autoUsed,
    //   usedCouponId: response.couponId,
    //   usedCouponCode: response.couponCode
    // })

    console.log(response);
  };

  return (
    <ThirdPartyContext.Provider
      value={{
        state,
        setState,
        createThirdPartyUser,
        linkUserToThirdPartyUser,
        autoUseRegisteredOrderedCoupon,
        autoUseFreeCoupon,
      }}
    >
      {children}
    </ThirdPartyContext.Provider>
  );
};
