import { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LikeEmptySVG,
  LikeFillSVG,
  PauseSVG,
  PlayListSVG,
  PlaySVG,
  RePlayAllSVG,
  RePlayOneSVG,
  ReplySVG,
  ShuffleSVG,
  SkipBackSVG,
  SkipForwardSVG,
  TimerSVG,
  VolumeOffSVG,
  VolumeOnSVG,
} from '../../assets';
import FallBackImg from '../../assets/images/fallback.jpg';
import { ModalContext } from '../../contexts/ModalContext';
import { MusicPlayerStateContext } from '../../contexts/MusicContext';
import formatDurationDisplay from '../../utils/formatDurationDisplay';
import { transLn } from '../../utils/transLn';
import IconButton from '../IconButton';
import TimerModal from '../modals/TimerModal';
import AudioProgressBar from './AudioProgressBar';
import VolumeInput from './VolumeInput';
import Swal from 'sweetalert2';
import { sweetTopSmallSuccessAlert, sweetTopSuccessAlert } from '../../utils/sweetAlert';
import { useTranslation } from 'react-i18next';

export default function AudioPlayer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const path = state?.path as string;
  const { t } = useTranslation();

  const { handleModal } = useContext(ModalContext);
  const {
    audioRef,
    currentPlayMusic,
    isReady,
    isMusicPlaying,
    volume,
    duration,
    currentProgress,
    buffered,
    currentSongIndex,
    playList,
    rePlayState,
    shuffleState,
    handleSetTimer,
    onClickRePlay,
    onClickShuffle,
    onClickLikeBtn,
    togglePlayPause,
    handleMuteUnmute,
    handleVolumeChange,
    onChangeCurrentProgressBar,

    onPrev,
    onNext,
  } = useContext(MusicPlayerStateContext);

  const [isHidden, setIsHidden] = useState(false);
  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currentProgress);
  const [timerSeconds, setTimerSeconds] = useState<number>(0);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [fadeOutEnabled, setFadeOutEnabled] = useState<boolean>(false);
  const [originalVolume, setOriginalVolume] = useState<number>(volume);

  /** 음원 상세 페이지 이동 */
  const handleMusicDetail = () => {
    if (
      window.location.pathname.includes('/app/home/comment/') ||
      window.location.pathname.includes('/app/home/playlist')
    ) {
      navigate(path ?? '/app/home');
    } else {
      navigate(`/app/player/${currentPlayMusic?.id}`, {
        state: {
          path: window.location.pathname,
        },
      });
    }
  };

  /** 플레이 리스트 페이지 이동. */
  const handleNavigatePlayListPage = () => {
    navigate('/app/home/playlist');
  };

  /** 음원 댓글 페이지 이동. */
  const onClickReply = () => {
    navigate(`/app/home/comment/${currentPlayMusic?.id}`);
  };
  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setIsTimerActive(false);
    setTimeLeft(null);
  };
  const startTimer = (seconds: number, fadeOut: boolean) => {
    stopTimer();
    setTimerSeconds(seconds);
    setTimeLeft(seconds);
    setFadeOutEnabled(fadeOut);
    setIsTimerActive(true);
    sweetTopSmallSuccessAlert(t('timer.startTimer'), 2000);
    setOriginalVolume(volume);
  };

  const countdownTimer = () => {
    if (timeLeft !== null && timeLeft > 0) {
      setTimeLeft((prevTime) => (prevTime ? prevTime - 1 : 0));

      if (fadeOutEnabled && timeLeft <= 10) {
        const fadeRatio = timeLeft / 10;
        if (audioRef?.current) {
          audioRef.current.volume = fadeRatio * originalVolume;
        }
      }
    } else {
      stopTimer();
      togglePlayPause();
      sweetTopSmallSuccessAlert(t('timer.endTimer'));
      if (audioRef?.current) {
        audioRef.current.volume = originalVolume;
      }
    }
  };

  useEffect(() => {
    if (isTimerActive) {
      timerRef.current = setInterval(countdownTimer, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isTimerActive, timeLeft]);

  useEffect(() => {
    if (window.location.pathname.includes('/app/player/')) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [window.location.pathname, isHidden]);

  if (currentPlayMusic === null) return null;

  return (
    <div className={`${isHidden ? 'hidden' : 'visible'} w-full h-[80px]`}>
      <div className="relative flex justify-between md:justify-start md:gap-20 p-2 bg-slate-900/70 text-slate-400 h-[80px]">
        <AudioProgressBar
          duration={duration}
          currentProgress={currentProgress}
          buffered={buffered}
          onChange={(e) => {
            if (!audioRef?.current) return;
            audioRef.current.currentTime = e.currentTarget.valueAsNumber;
            onChangeCurrentProgressBar(e.currentTarget.valueAsNumber);
          }}
        />
        {currentPlayMusic && (
          <div className="flex">
            <div className="flex items-center gap-2 w-fit h-fit" onClick={handleMusicDetail}>
              <img
                src={`${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.thumbnail}`}
                className="min-w-[64px] h-[64px] rounded-lg"
                aria-label="thumbnail"
                onError={(event) => {
                  event.currentTarget.src = FallBackImg;
                }}
              />
              <div className="flex flex-col gap-1 w-[170px]">
                <p className="font-medium truncate text-N1 text-ellipsis">
                  {transLn(currentPlayMusic.title) ?? 'Select a song'}
                </p>
                <p className="text-N3 text-[14px] truncate">{transLn(currentPlayMusic.category)}</p>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center gap-3 max-md:hidden">
          <IconButton
            intent="secondary"
            size="sm"
            aria-label={volume === 0 ? 'unmute' : 'mute'}
            onClick={handleMuteUnmute}
          >
            {volume === 0 ? (
              <VolumeOffSVG style={{ width: 20, height: 20 }} className="fill-white" />
            ) : (
              <VolumeOnSVG style={{ width: 20, height: 20 }} className="fill-white" />
            )}
          </IconButton>
          <VolumeInput volume={volume} onVolumeChange={handleVolumeChange} />
        </div>
        <div className="shrink-0 flex items-center justify-between w-[205px] max-md:hidden">
          <IconButton disabled={!isReady} size="sm" onClick={onClickRePlay}>
            {rePlayState === 0 && <RePlayAllSVG fill="grey" width={20} />}
            {rePlayState === 1 && <RePlayOneSVG width={20} />}
            {rePlayState === 2 && <RePlayAllSVG fill="white" width={20} />}
          </IconButton>
          <IconButton disabled={!isReady || currentSongIndex === 0} size="sm" onClick={onPrev}>
            <SkipBackSVG width={20} />
          </IconButton>
          <IconButton
            disabled={!isReady}
            onClick={togglePlayPause}
            className="w-[36px] h-[36px] flex justify-center items-center"
          >
            {!isReady && currentPlayMusic ? (
              <div>loading</div>
            ) : isMusicPlaying ? (
              <PauseSVG width={30} />
            ) : (
              <PlaySVG width={30} />
            )}
          </IconButton>
          <IconButton disabled={!isReady || currentSongIndex === playList.length - 1} onClick={onNext}>
            <SkipForwardSVG width={20} />
          </IconButton>
          <IconButton disabled={!isReady} size="sm" onClick={onClickShuffle}>
            {!shuffleState ? <ShuffleSVG fill="grey" width={20} /> : <ShuffleSVG fill="white" width={20} />}
          </IconButton>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-[20px] max-md:hidden">
            <IconButton disabled={!isReady} size="sm" onClick={onClickReply}>
              <ReplySVG className="fill-white" width={20} />
            </IconButton>
            <IconButton
              disabled={!isReady}
              onClick={() => {
                if (isTimerActive) {
                  Swal.fire({
                    background: '#212226',
                    title: t('timer.timerAlreadySet'),
                    width: 350,
                    html: `<p class="text-sm md:text-base text-white">${t('timer.cancelDetail')}</p>`,
                    position: 'center',
                    icon: 'info',
                    showCancelButton: true,
                    cancelButtonText: t('timer.cancel'),
                    confirmButtonText: t('timer.confirm'),
                    customClass: {
                      popup: 'w-full max-w-[90%] md:max-w-[400px] bg-silver',
                      title: 'text-lg md:text-xl font-semibold text-white',
                      validationMessage: 'text-sm md:text-base text-red-500',
                      confirmButton: 'px-4 py-2 bg-[#515D8F] text-white rounded-lg',
                      cancelButton: 'px-4 py-2 bg-inherit border border-solid text-white rounded-lg ml-2',
                      icon: 'w-14 h-14',
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      stopTimer();
                      sweetTopSmallSuccessAlert(t('timer.endTimer'));
                    }
                  });
                } else {
                  handleModal(<TimerModal handleSetTimer={(seconds, fadeOut) => startTimer(seconds, fadeOut)} />);
                }
              }}
            >
              <div className="flex items-center gap-1">
                <TimerSVG className="fill-white" width={20} />
                {timeLeft !== null && <span className="text-white text-sm">{formatDurationDisplay(timeLeft)}</span>}
              </div>
            </IconButton>

            <IconButton disabled={!isReady} size="sm" onClick={() => onClickLikeBtn()}>
              {currentPlayMusic && currentPlayMusic.isLiked ? (
                <LikeFillSVG className="fill-white" width={20} />
              ) : (
                <LikeEmptySVG className="fill-white" width={20} />
              )}
            </IconButton>
          </div>
          <span className=" text-right text-N0 max-md:hidden w-[110px]">
            {elapsedDisplay} / {durationDisplay}
          </span>
          <IconButton
            disabled={!isReady}
            size="lg"
            onClick={togglePlayPause}
            className="w-[24px] h-[24px] flex justify-center items-center md:hidden"
          >
            {!isReady && currentPlayMusic ? <div>loading</div> : isMusicPlaying ? <PauseSVG /> : <PlaySVG />}
          </IconButton>
          <IconButton size="sm" onClick={handleNavigatePlayListPage}>
            <PlayListSVG />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
