import clsx from 'clsx';
import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CancelSVG, LOGOSVG } from '../../assets';
import AuthAxios from '../../axios';
import IconButton from '../../components/IconButton';
import { ModalContext } from '../../contexts/ModalContext';
import MarketingInformationPage from '../Consent/MarketingInforamtionPage';
import PrivatePolicyPage from '../Consent/PrivatePolicyPage';
import TermsOfUsePage from '../Consent/TermsOfUsePage';
import { ThirdPartyContext } from '../../contexts/ThirdPartyContext';

export default function AgrementPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allCheck, setAllCheck] = useState(false);
  const [ageCheck, setAgeCheck] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [privateCheck, setPrivateCheck] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [thirdPartyCheck, setThirdPartyCheck] = useState(false);
  const { state: collabarationState } = useContext(ThirdPartyContext);

  const onClicCheckAll = (flag: boolean) => {
    if (flag) {
      setAllCheck(flag);
      setTermCheck(flag);
      setPrivateCheck(flag);
      setSmsCheck(flag);
      setAgeCheck(flag);
      setThirdPartyCheck(flag);
    } else {
      setAllCheck(flag);
      setTermCheck(flag);
      setPrivateCheck(flag);
      setSmsCheck(flag);
      setAgeCheck(flag);
      setThirdPartyCheck(flag);
    }
  };
  useEffect(() => {
    // termCheck privateCheck smsCheck ageCheck 하나라도 false이면 setAllCheck(false) 수행한다.
    if (termCheck && privateCheck && smsCheck && ageCheck) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [termCheck, privateCheck, smsCheck, ageCheck]);

  const onClickStartButton = () => {
    // console.log(termCheck, privateCheck, smsCheck, ageCheck);
    AuthAxios.put('/users/terms-agree', {
      requiredConsents: termCheck && privateCheck && ageCheck,
      pushNotificationConsent: smsCheck,
      thirdPartyInformationConsent: thirdPartyCheck,
    })
      .then((resolve) => {
        if (collabarationState.thirdPartyCode === 'DBLIFE') {
          return navigate('/auth/basicInfo-db');
        }
        return navigate('/auth/basicInfo', { replace: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const consents: {
    content: string;
    modal?: JSX.Element;
    isChecked: boolean;
    handleCheck: (checked: boolean) => void;
    className?: string;
  }[] = [
    {
      content: t('web.ageConfirmation'),
      isChecked: ageCheck,
      handleCheck: setAgeCheck,
    },
    {
      content: t('web.termsOfUse'),
      modal: (
        <AgreementModal
          title={t('web.termsOfUse')}
          content={<TermsOfUsePage />}
          check={termCheck}
          checkFunction={setTermCheck}
        />
      ),
      isChecked: termCheck,
      handleCheck: setTermCheck,
    },
    {
      content: t('web.privacyConsent'),
      modal: (
        <AgreementModal
          title={t('web.privacyConsent')}
          content={<PrivatePolicyPage />}
          check={privateCheck}
          checkFunction={setPrivateCheck}
        />
      ),
      isChecked: privateCheck,
      handleCheck: setPrivateCheck,
    },
    {
      content: t('web.marketingConsent'),
      modal: (
        <AgreementModal
          title={t('web.marketingConsent')}
          content={<MarketingInformationPage />}
          check={smsCheck}
          checkFunction={setSmsCheck}
        />
      ),
      isChecked: smsCheck,
      handleCheck: setSmsCheck,
    },
    // {
    //   content: t('web.thirdPartyInformationConsent'),
    //   modal: (
    //     <AgreementModal
    //       title={t('web.thirdPartyInformationConsent')}
    //       content={<ThirdPartyInformationPage />}
    //       check={thirdPartyCheck}
    //       checkFunction={setThirdPartyCheck}
    //     />
    //   ),
    //   isChecked: thirdPartyCheck,
    //   handleCheck: setThirdPartyCheck,
    // },
  ];

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <LOGOSVG className="mb-[30px] w-[152px] md:w-[163px]" />
      <div className="text-lg font-bold text-N0 mb-[53px] md:text-xl">{t('web.welcome')}</div>
      <fieldset>
        <div className="w-[288px] h-[222px] mb-[43px] text-N0 flex flex-col gap-[8px] text-[15px] md:w-[360px]">
          <CheckInput
            content={t('web.agreeAll')}
            isChecked={allCheck}
            handleCheck={onClicCheckAll}
            className="text-lg"
          />
          {/* <div className="text-[18px] custom-checkbox-container">
            <input
              id="allCheck" // 고유 ID 추가
              type="checkbox"
              name="all"
              checked={allCheck}
              className="mr-4 hidden-checkbox" // 실제 체크박스 숨김 처리와 margin-right 유지
              onChange={(e) => onClicCheckAll(e.currentTarget.checked)}
            />
            <label
              htmlFor="allCheck" // 수정된 고유 ID로 변경
              className="w-full custom-checkbox-label"
            >
              {t('web.agreeAll')}
              <span
                className="custom-checkbox-mark"
                style={{
                  height: '24px', // 높이 24px로 조정
                  width: '24px', // 너비 24px로 조정
                }}
              ></span>{' '}
            </label>
          </div> */}

          <hr className="w-full my-[24px] bg-N3 " />

          <div className="flex flex-col gap-3">
            {consents.map((c) => (
              <CheckInput
                key={c.content}
                content={c.content}
                modal={c.modal}
                isChecked={c.isChecked}
                handleCheck={c.handleCheck}
              />
            ))}
          </div>

          {/* <div className="custom-checkbox-container">
            <input
              id="d" // `htmlFor`와 매치되도록 id 추가
              type="checkbox"
              name="d"
              checked={ageCheck}
              className="hidden-checkbox" // 실제 체크박스 숨김 처리
              onChange={(e) => setAgeCheck(e.currentTarget.checked)}
            />
            <label
              htmlFor="d" // `input`의 id와 매치되도록 수정
              className="w-full custom-checkbox-label" // 필요에 따라 추가 스타일 클래스 적용
            >
              {t('web.ageConfirmation')}
              <span className="custom-checkbox-mark"></span>
              <span className="float-right max-md:hidden">&gt;</span>
            </label>
          </div>
          <div className="custom-checkbox-container">
            <input
              id="termCheck" // 고유 ID 추가
              type="checkbox"
              name="a"
              checked={termCheck}
              className="hidden-checkbox" // 클래스 이름 변경 및 실제 체크박스 숨김 처리
              onChange={(e) => setTermCheck(e.currentTarget.checked)}
            />
            <label
              htmlFor="termCheck" // 수정된 고유 ID로 변경
              className="w-full custom-checkbox-label"
              onClick={() =>
                handleModal(
                  <AgreementModal
                    title={t('web.termsOfUse')}
                    content={<TermsOfUsePage />}
                    check={termCheck}
                    checkFunction={setTermCheck}
                  />
                )
              }
            >
              {t('web.termsOfUse')}
              <span className="custom-checkbox-mark"></span>
              <span className="float-right max-md:hidden">&gt;</span>
            </label>
          </div>
          <div className="custom-checkbox-container">
            <input
              id="privateCheck" // 고유 ID 추가
              type="checkbox"
              name="b"
              checked={privateCheck}
              className="hidden-checkbox" // 클래스 이름 변경 및 실제 체크박스 숨김 처리
              onChange={(e) => setPrivateCheck(e.currentTarget.checked)}
            />
            <label
              htmlFor="privateCheck" // 수정된 고유 ID로 변경
              className="w-full custom-checkbox-label"
              onClick={() =>
                handleModal(
                  <AgreementModal
                    title={t('web.privacyConsent')}
                    content={<PrivatePolicyPage />}
                    check={privateCheck}
                    checkFunction={setPrivateCheck}
                  />
                )
              }
            >
              {t('web.privacyConsent')}
              <span className="custom-checkbox-mark"></span>
              <span className="float-right max-md:hidden">&gt;</span>
            </label>
          </div>

          <div className="custom-checkbox-container">
            <input
              id="c" // htmlFor와 매치되도록 id 추가
              type="checkbox"
              name="c"
              checked={smsCheck}
              className="hidden-checkbox" // 실제 체크박스 숨김 처리
              onChange={(e) => setSmsCheck(e.currentTarget.checked)}
            />
            <label
              htmlFor="c" // input의 id와 매치되도록 수정
              className="w-full custom-checkbox-label"
              onClick={() =>
                handleModal(
                  <AgreementModal
                    title={t('web.marketingConsent')}
                    content={<MarketingInformationPage />}
                    check={smsCheck}
                    checkFunction={setSmsCheck}
                  />
                )
              }
            >
              {t('web.marketingConsent')}
              <span className="custom-checkbox-mark"></span>
              <span className="float-right max-md:hidden">&gt;</span>
            </label>
          </div> */}

          {/* <CheckInput
            content={t('web.thirdPartyInformationConsent')}
            isChecked={thirdPartyCheck}
            handleCheck={setThirdPartyCheck}
            modal={
              <AgreementModal
                title={t('web.thirdPartyInformationConsent')}
                content={<ThirdPartyInformationPage />}
                check={thirdPartyCheck}
                checkFunction={setThirdPartyCheck}
              />
            }
          /> */}
        </div>
      </fieldset>
      <button
        onClick={onClickStartButton}
        disabled={!termCheck || !privateCheck || !ageCheck}
        className={clsx('w-[288px] h-[48px] rounded-lg text-base font-medium md:w-[360px] md:h-[56px]', {
          'bg-N2 text-N4': !(termCheck && privateCheck && ageCheck),
          'bg-N9 text-N2': termCheck && privateCheck && ageCheck,
        })}
      >
        {t('web.start')}
      </button>
    </div>
  );
}

function CheckInput({
  modal,
  isChecked,
  handleCheck,
  content,
  className,
}: {
  modal?: JSX.Element;
  isChecked: boolean;
  handleCheck: (checked: boolean) => void;
  content: string;
  className?: string;
}) {
  const { handleModal } = useContext(ModalContext);

  const labelClickHandler: MouseEventHandler<HTMLLabelElement> = (event) => {
    event.preventDefault();

    if (modal && !isChecked) {
      handleModal(modal);
    } else {
      handleCheck(!isChecked);
    }
  };

  return (
    <div className={clsx('w-full flex flex-row', className)}>
      <label
        className="grow flex flex-row justify-start items-center gap-1 hover:cursor-pointer"
        onClick={labelClickHandler}
      >
        <div className="h-5 aspect-square bg-white rounded-full p-1">
          <div
            className={clsx('w-full h-full rounded-full', {
              'bg-N5': isChecked,
              'bg-white': !isChecked,
            })}
          />
        </div>

        <input
          type="checkbox"
          name="thirdPary"
          checked={isChecked}
          className="appearance-none" // 실제 체크박스 숨김 처리
          onChange={(e) => handleCheck(e.currentTarget.checked)}
        />

        {content}
        {/* <span className="custom-checkbox-mark"></span> */}
      </label>
      <span className="float-right max-md:hidden">&gt;</span>
    </div>
  );
}

interface IAgreementProps {
  content: React.ReactNode;
  title: string;
  check: boolean;
  checkFunction: Function;
}
export function AgreementModal(props: IAgreementProps) {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const { content, title, check, checkFunction } = props;
  const [_check, setCheck] = useState(check);
  const handleCheck = (e: boolean) => {
    setCheck(e);
    checkFunction(e);
  };
  return (
    <div className="w-[339px] min-h-[504px] md:w-[788px] md:min-h-[504px] rounded-[20px] bg-N0 px-[18px] py-[15px] flex flex-col items-center">
      <div className="flex justify-end w-full">
        <IconButton
          onClick={() => handleModal()}
          className="w-[24px] h-[24px] rounded-full bg-N9 flex justify-center items-center"
        >
          <CancelSVG />
        </IconButton>
      </div>
      <header className="w-full text-[20px] mb-[34px]">{title}</header>
      <main className="w-[300px] md:w-[742px] md:h-[275px] rounded-[20px] border border-N8 h-[275px] px-[20px] py-[22px]">
        <div className="w-full h-full overflow-y-scroll text-black">{content}</div>
      </main>
      <div className="flex items-center justify-start w-full mt-[14px] mb-[18px] mx-auto">
        <label className="pl-4 py-2 hover:cursor-pointer">
          <input
            type="checkbox"
            checked={_check}
            className="mr-4"
            onChange={(e) => handleCheck(e.currentTarget.checked)}
          />
          {t('web.termsAgreement')}
        </label>
      </div>
      <button onClick={() => handleModal()} className="w-[287px] h-[48px] rounded-lg bg-N9 text-N0 text-[15px]">
        {t('web.confirm')}
      </button>
    </div>
  );
}
