import { ClassAttributes, ButtonHTMLAttributes, useContext } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { JSX } from 'react/jsx-runtime';
import { AppleLogin } from '../../assets';
import { jwtDecode } from 'jwt-decode';
import AuthAxios from '../../axios';
import { getItem, LocalLanguage, setItem, USERINFO, USERTOKEN } from '../../utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';

export default function AppleLoginBtn({
  afterSocialLoginSuccess,
}: {
  afterSocialLoginSuccess: ({ type, socialId, email }: { type: string; socialId: string; email: string }) => void;
}) {
  const navigate = useNavigate();
  const { setLoginSucssess } = useContext(AuthStateContext);
  const { handleModal } = useContext(ModalContext);

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: `${process.env.REACT_APP_APPLE_CLIENT_ID}`,
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: 'email name',
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: `${process.env.REACT_APP_APPLE_REDIRECT_URL}`,
        /** State string that is returned with the apple response */
        state: 'state',
        /** Nonce */
        nonce: 'nonce',
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      uiType="dark"
      /** className */
      className="apple-auth-btn"
      /** Removes default style tag */
      noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      buttonExtraChildren="Continue with Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={(response: any) => {
        // console.log("애플 로그인 성공", response);
        const { authorization } = response;
        const { id_token, code } = authorization;
        const decodedToken = jwtDecode<any>(id_token);
        const { sub, email } = decodedToken;
        // console.log("토큰 분해", decodedToken);
        afterSocialLoginSuccess({
          type: 'apple',
          socialId: sub,
          email: email,
        });
        // AuthAxios.post(`/auth/social`, {
        //   type: "apple",
        //   socialId: sub,
        //   mail: email,
        // })
        //   .then((resolve) => {
        //     const { user, accessToken, refreshToken } = resolve.data;

        //     setItem(USERINFO, user);
        //     setItem(USERTOKEN, { accessToken, refreshToken });
        //     setLoginSucssess(user);
        //     if (user.termsAgreed) {
        //       // navigate("/app/home", { replace: true });
        //       if (user.gender && user.birthday) {
        //         navigate("/app/home", { replace: true });
        //       } else {
        //         navigate("/auth/basicInfo", { replace: true });
        //       }
        //     } else {
        //       navigate("/auth/agrement", { replace: true });
        //     }
        //   })
        //   .catch((error) => {
        //     navigate("/auth", { replace: true });
        //     // console.log(error.response.data.message ?? "");
        //     handleModal(
        //       <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
        //         <div>{error.response.data.message ?? ""}</div>
        //         <button
        //           className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
        //           onClick={() => handleModal()}
        //         >
        //           확인
        //         </button>
        //       </div>
        //     );
        //   });
      }} // default = undefined
      /** Called upon signin error */
      onError={(error: any) => {
        console.error('애플 로그인 실패', error);
        navigate('/auth', { replace: true });
      }} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */

      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={(
        props: JSX.IntrinsicAttributes & ClassAttributes<HTMLButtonElement> & ButtonHTMLAttributes<HTMLButtonElement>
      ) => (
        <button {...props} className="flex pt-[6.72px]  justify-center w-12 h-12 bg-black rounded-lg ">
          <AppleLogin />
        </button>
      )}
    />
  );
}
