import { useContext } from 'react';
import AuthAxios from '../../axios';
import { Login } from '../../types';
import { useNavigate } from 'react-router-dom';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';
import { getItem, LocalLanguage, setItem, USERINFO, USERTOKEN } from '../../utils/localStorage';
import FacebookLoginBtn from './FacebookLoginBtn';
import AppleLoginBtn from './AppleLoginBtn';
import GoogleLoginBtn from './GoogleLoginBtn';
import NaverLoginBtn from './NaverLoginBtn';
import KakaoLoginBtn from './KakaoLoginBtn';

export default function SocialLoginButtons() {
  const lan = getItem(LocalLanguage);
  const navigate = useNavigate();
  const { setLoginSucssess } = useContext(AuthStateContext);
  const { handleModal } = useContext(ModalContext);

  const postSignIn = async ({ type, socialId, email, forceSignUp }: {type: string; socialId: string; email: string; forceSignUp?: boolean}) => {
    return AuthAxios.post<Login>('/auth/social', { type, socialId, email, forceSignUp })
      .then((resolve) => {
        const { user, accessToken, refreshToken, subscriptionInfo } = resolve.data;

        //subscriptionInfo 나중에 로그인 후 구독권 만료에 대한 고지용 팝업 .
        setItem(USERINFO, user);
        setItem(USERTOKEN, { accessToken, refreshToken });
        setLoginSucssess(user);

        return user;
      })
      .then((user) => {
        console.log('user:', user)
        if (user.termsAgreed) {
          if (user.gender && user.birthday) {
            return navigate('/app/home');
          } else {
            return navigate('/auth/basicInfo');
          }
        }
        return navigate('/auth/agrement');
      })
      .catch((error) => {
        navigate('/auth', { replace: true });
        // console.log(error.response.data.message ?? "");
        console.log('this!')
        if (error.response.status === 409 && (error.response.data.message as string).indexOf('email already exists') !== -1) {
          const message = error.response.data.message;
          const {email, socials} = error.response.data.options;
          console.log('thisthis!!!')
          handleModal(
            <div className="w-[300] bg-N10 flex flex-col items-center rounded-lg text-N0 p-2 px-4">
              <p>{lan === 'en' ? message : '이미 가입된 이메일입니다.'}</p>
              <p>{lan === 'en' ? 'To create a new social account with the same email, please click the button below.' : '동일한 이메일로 다른 소셜 계정을 등록하려면 아래 버튼을 누르세요.'}</p>
              <br/>
              <p>Email: {email}</p>
              <p>Social: {socials}</p>
              <div className="w-full h-20 flex flex-row place-content-center gap-6 text-N0 p-2 px-4">
                <button
                  className="flex-1 shrink-0 bg-N5 text-N10 rounded-lg text-base font-semibold"
                  onClick={() => handleModal()}
                >
                  {lan === 'en' ? 'Cancel' : '취소'}
                </button>
                <button
                  className="flex-1 shrink-0 bg-N5 text-N10 rounded-lg text-base font-semibold"
                  onClick={() => {
                    handleModal()
                    postSignIn({ type, socialId, email, forceSignUp: true })
                  }}
                >
                  {lan === 'en' ? 'Process Anyway' : '계속 진행하기'}
                </button>
              </div>
            </div>,
          );
          return;
        }

        let message = error.response.data.message;
        if (error.response.data.message === '재가입은 탈퇴 3개월 이후부터 가능합니다.' && lan === 'en') {
          message = 'You can re-sign up 3 months after deleting your account.';
        }
        handleModal(
          <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
            <div>{message ?? ''}</div>
            <button
              className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
              onClick={() => handleModal()}
            >
              {lan === 'en' ? 'ok' : '확인'}
            </button>
          </div>
        );
      });
  }

  const afterSocialLoginSuccess = ({ type, socialId, email }: { type: string; socialId: string; email: string }) => {
    return postSignIn({ type, socialId, email })
  };

  return (
    <>
      <FacebookLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
      <AppleLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
      <GoogleLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
      <NaverLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
      <KakaoLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
    </>
  );
}


