import { NaverLogin } from '../../assets';
import LoginNaver from 'react-login-by-naver';
import AuthAxios from '../../axios';
import { getItem, LocalLanguage, setItem, USERINFO, USERTOKEN } from '../../utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';

export default function NaverLoginBtn({ afterSocialLoginSuccess }) {
  const { setLoginSucssess } = useContext(AuthStateContext);
  const { handleModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const handleLogin = (naverUser) => {
    console.log('naverUser INFO::', naverUser);
    //setItem(USERINFO, naverUser);

    afterSocialLoginSuccess({
      type: 'naver',
      socialId: naverUser.id,
      email: naverUser.email,
    });

    // AuthAxios.post(`/auth/social`, {
    //   type: "naver",
    //   socialId: naverUser.id,
    //   email: naverUser.email,
    // })
    //   .then((resolve) => {
    //     const { user, accessToken, refreshToken } = resolve.data;

    //     setItem(USERINFO, user);
    //     setItem(USERTOKEN, { accessToken, refreshToken });
    //     setLoginSucssess(user);
    //     if (user.termsAgreed) {
    //       if (user.gender && user.birthday) {
    //         navigate("/app/home", { replace: true });
    //       } else {
    //         navigate("/auth/basicInfo", { replace: true });
    //       }
    //     } else {
    //       navigate("/auth/agrement", { replace: true });
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //     handleModal(
    //       <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
    //         <div>{error.response.data.message ?? ""}</div>
    //         <button
    //           className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
    //           onClick={() => handleModal()}
    //         >
    //           확인
    //         </button>
    //       </div>
    //     );
    //   });
  };

  return (
    <LoginNaver
      clientId={process.env.REACT_APP_NAVER_CLIENT_ID}
      callbackUrl={process.env.REACT_APP_CALLBACK_URL}
      render={(props) => (
        <button onClick={props.onClick} className="flex items-center justify-center w-12 h-12 bg-green-500 rounded-lg ">
          <NaverLogin />
        </button>
      )}
      onSuccess={(result) => {
        console.log('success!!')
        console.log('naver1', result);
        // console.log(result.profile.id); //소셜아이디
        // console.log(result.profile.kakao_account.email); //이메일
        handleLogin({id: result.id, type: 'naver', email: result.email});
        // AuthAxios.post(`/auth/social`, {
        //   type: 'naver',
        //   socialId: result.id,
        //   email: result.email,
        // })
        //   .then((resolve) => {
        //     const { user, accessToken, refreshToken } = resolve.data;
        //
        //     setItem(USERINFO, user);
        //     setItem(USERTOKEN, { accessToken, refreshToken });
        //     setLoginSucssess(user);
        //     if (user.termsAgreed) {
        //       if (user.gender && user.birthday) {
        //         navigate('/app/home', { replace: true });
        //       } else {
        //         navigate('/auth/basicInfo', { replace: true });
        //       }
        //     } else {
        //       navigate('/auth/agrement', { replace: true });
        //     }
        //   })
        //   .catch((error) => {
        //     // console.log(error);
        //     handleModal(
        //       <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
        //         <div>{error.response.data.message ?? ''}</div>
        //         <button
        //           className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
        //           onClick={() => handleModal()}
        //         >
        //           확인
        //         </button>
        //       </div>
        //     );
        //   });
      }}
      onFailure={() => console.error('failed')}
    />
  );
}
