import React, { useContext, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BtnPlay from '../../assets/DBInsurance/Btn_Play.svg';
import BtnPause from '../../assets/DBInsurance/Btn_Pause.svg';
import './CustomRadioButton.css';
import Sleep from '../../assets/DBInsurance/sleep_induction.svg';
import Stress from '../../assets/DBInsurance/stress.svg';
import Meditation from '../../assets/DBInsurance/meditation.svg';
import InderectExperience from '../../assets/DBInsurance/inderect_experience.svg';
import Concentration from '../../assets/DBInsurance/concentration.svg';
import Appetite from '../../assets/DBInsurance/reducing-appetite.svg';
import { AIRecommendedTracks } from '../../types';
import { AuthStateContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

// const icons = [
//   {
//     name: 'meditation',
//     icon: Meditation,
//   },
//   {
//     name: 'sleep',
//     icon: Sleep,
//   },
//   {
//     name: 'appetite',
//     icon: Appetite,
//   },
//   {
//     name: 'concentration',
//     icon: Concentration,
//   },
//   {
//     name: 'stress',
//     icon: Stress,
//   },
//   {
//     name: 'experience',
//     icon: InderectExperience,
//   },
// ];

const icons = {
  // meditation: {
  //   icon: Meditation,
  // },
  sleep: {
    icon: Sleep,
  },
  appetite: {
    icon: Appetite
  },
  concentration: {
    icon: Concentration
  },
  stress: {
    icon: Stress
  }
}

interface SliderProps {
  tracks: AIRecommendedTracks[];
  handleUnauthenticatedUser: () => void;
  isSurveyDone: boolean;
}

export const SurveySlider: React.FC<SliderProps> = ({ tracks, handleUnauthenticatedUser, isSurveyDone }) => {
  /** Initialization */
  const { t } = useTranslation();
  const [playingTrack, setPlayingTrack] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { isAuthentication } = useContext(AuthStateContext);

  /** Handlers */
  const handlePlayClick = (url: string) => {
    if (!isAuthentication && isSurveyDone) {
      return handleUnauthenticatedUser();
    }

    if (audioRef.current) {
      if (playingTrack === url) {
        if (audioRef.current.paused) {
          audioRef.current.play();
          setIsPlaying(true);
        } else {
          audioRef.current.pause();
          setIsPlaying(false);
        }
      } else {
        audioRef.current.src = url;
        audioRef.current.play();
        setPlayingTrack(url);
        setIsPlaying(true);
      }
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    appendDots: (dots: any) => (
      <div className="dots-container">
        <ul className="dots">{dots}</ul>
      </div>
    ),
    customPaging: () => <div className="dot bg-gray-400 rounded transition-all duration-300"></div>,
    arrows: false,
  };
  console.log('tracks::=>', tracks);
  return (
    <div className="relative w-full h-[85%] md:h-auto overflow-y-scroll  no-scrollbar">
      <Slider {...settings}>
        {tracks?.map((track) => (
          <div key={track.id} className="flex flex-col relative md:h-[35rem] ">
            <img
              src={`${process.env.REACT_APP_IMG_ENDPOINT}/${track.artwork}`}
              className="w-full h-[28rem] md:h-[36.0625rem] object-cover mb-7"
              alt=""
            />
            <img
              src={
                playingTrack === `${process.env.REACT_APP_IMG_ENDPOINT}/${track.url}` && isPlaying ? BtnPause : BtnPlay
              }
              className="absolute w-[40%] md:w-[20%] top-[20%] md:top-[40%] left-[32%] md:left-[40%] cursor-pointer object-contain "
              alt="Play button"
              onClick={() => handlePlayClick(`${process.env.REACT_APP_IMG_ENDPOINT}/${track.url}`)}
            />

            <h1 className="hidden md:flex absolute bottom-[44%] left-[13%] md:top-[5%] md:left-[15%] font-bold text-white text-2xl lg:my-[2rem]">
              사운드필
            </h1>

            <div className="flex flex-row items-center justify-center max-w-[10rem] h-[2rem] rounded-full absolute md:bottom-0 left-5 md:left-[12%] mx-5 mt-10 bg-[#788296]">
              <img src={icons[track.category].icon} className="px-2" />
              <p className="pr-2 text-white text-[13px]">
                {t(`survey.trackCategory.${track.category}`)}
              </p>
            </div>
            <div className="w-full h-[12.5rem]">
              <h1 className="mt-[5.2rem] md:mt-6 ml-6  md:ml-[6rem] text-[22px] text-left font-bold">
                {track.title.ko}
              </h1>
              <p className=" ml-6  md:ml-[6rem] mt-3 text-[18px] text-left font-[500]" style={{ color: '#788296' }}>
                {track.tag?.ko}
              </p>
              <p className="mx-6 md:mx-[6rem] text-[18px] text-left font-[500] mt-3">{track.description?.ko}</p>
            </div>
          </div>
        ))}
      </Slider>
      <audio ref={audioRef} />
    </div>
  );
};
