import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LOGOSVG } from '../../assets';
import ExploreBtn from '../../components/common/ExploreBtn';
import OpenAppBtn from '../../components/common/OpenAppBtn';
import SocialLoginButtons from '../../components/socialLogin/SocialLoginButtons';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ThirdPartyContext } from '../../contexts/ThirdPartyContext';
import { ModalContext } from '../../contexts/ModalContext';

export default function LoginMainPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoginSucssess } = useContext(AuthStateContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<AxiosError | null>(null);
  const [searchParams] = useSearchParams();
  const { state: collaborationState, setState: collaborationSetState } = useContext(ThirdPartyContext);
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    // 살루스케어(비타포트)에서 유입된 유저
    if (!searchParams.has('provNo')) {
      return;
    }

    collaborationSetState({
      ...collaborationState,
      thirdPartyId: searchParams.get('provNo') || '',
      thirdPartyCode: searchParams.get('provNo') === '11' ? 'VITAPORT' : undefined,
      thirdPartyUserId: searchParams.get('mberNo') || '',
      orderNumber: searchParams.get('orderNo') || '',
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="flex items-center justify-between w-full px-5 mt-5 md:flex-row-reverse mb-[128px]">
        <LOGOSVG className="md:hidden w-[93px] " />
        <div className="flex gap-[6.75px]">
          <OpenAppBtn />
          <ExploreBtn />
        </div>
      </div>

      <div className="flex flex-col items-center text-lg font-bold text-N0 leading-[26px] mb-3 md:mb-[21px] md:text-xl">
        {/* font pretendard */}
        <LOGOSVG className="max-md:hidden mb-[34px] md:w-[163px]" />
        <h1 className="text-center md:w-[30rem]">{t('web.introText')}</h1>
      </div>

      {/* <form
        className="flex flex-col items-center w-full gap-4 mb-[22px]"
        onSubmit={(e) => {
          e.preventDefault();
          // console.log("email", email);
          // console.log("password", password);
          handleLogin();
        }}
      >
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder={t("web.emailAddress")}
          className="focus:outline-none w-[288px]  h-[48px]  rounded-lg p-3 text-base text-N3 focus:placeholder:drop-shadow-md md:w-[360px] md:h-[56px]"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          placeholder={t("web.password")}
          className="focus:outline-none w-[288px] h-[48px] rounded-lg p-3 text-base text-N3 focus:placeholder:drop-shadow-md md:w-[360px] md:h-[56px]"
        />
        {error && (
          <div className="w-[288px] md:w-[360px] bg-red-600 text-N0 text-sm rounded-[5px] text-center">
            {t("loginSignupAlert.incorrectEmailOrPassword")}

          </div>
        )}
        <button
          type="submit"
          className="rounded-lg w-[288px] h-[48px] text-N2 leading-6 md:w-[360px] md:h-[56px]"
          style={{
            textAlign: "center",
            backgroundImage: "linear-gradient(160deg, #473CBC, #202E67)",
          }}
        >
          {t("web.start")}
        </button>
      </form> */}

      {/* <Link
        to={"/auth/forgotPassword"}
        className="underline text-[15px] font-medium text-N2 mb-[28px]"
      >
        {t("web.resetPassword")}
      </Link> */}

      <div className="w-[288px] h-[18px] flex items-center justify-between mb-[39px] md:w-[360px]">
        {/* <div className="w-[117.33px] h-[1px] bg-N3 md:w-[152px]" />
        <span className="text-[13px] leading-[18px] text-N3">
          {t("web.or")}
        </span>
        <div className="w-[117.33px] h-[1px] bg-N3 md:w-[152px]" /> */}
      </div>

      <div className=" md:w-[18rem] h-[82px]  flex flex-col items-center justify-between mb-[56px]">
        <span className="text-[15px] text-N1 font-medium">{t('web.socialLogin')}</span>
        <div className="flex justify-between w-full h-[48px] gap-1 md:gap-3">
          <SocialLoginButtons />
          {/* <FacebookLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
          <AppleLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
          <GoogleLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
          <NaverLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
          <KakaoLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} /> */}
        </div>
      </div>

      {/* <Link
        to={"/auth/signWithEmail"}
        className="underline text-[15px] font-medium text-N1 mb-[92px]"
      >
        {t("web.emailSignup")}
      </Link> */}
    </div>
  );
}
