import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftSVG } from '../../assets';
import AuthAxios from '../../axios';
import IconButton from '../../components/IconButton';

import { CategoryInfo } from '../../types';

import { transLn } from '../../utils/transLn';
import { convertCategoryToId } from './Main';

export default function InfoPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  console.log('설마 이게 ', id);
  const [currentTab, setCurrentTab] = useState(true);
  const [data, setData] = useState<CategoryInfo>();

  useLayoutEffect(() => {
    AuthAxios.get(`/category/${convertCategoryToId(id!)}`)
      .then((resolve) => {
        console.log(resolve.data);
        setData(resolve.data);
      })
      .catch((error) => console.log(error));
  }, [id]);

  return (
    <div className="h-full p-4 sm:mx-20 overflow-y-auto no-scrollbar">
      <div className="flex justify-between mb-[22px]">
        <div className="flex items-center gap-2">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowLeftSVG />
          </IconButton>
          <span className="text-lg font-bold text-N1">
            {t(`navigator.${id}`)}
            {/* {id} */}
          </span>
        </div>
      </div>

      <div className="flex w-full gap-10 max-md:flex-col">
        <div className="w-full md:w-1/2 ">
          <video
            controls
            poster={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.thumbnail}`}
            src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.video}`}
          />
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex flex-col w-full gap-4">
            <header className="text-lg font-semibold text-N1">{t('doctorPage.participatingMD')}</header>
            <main className="flex w-full gap-4 overflow-x-auto no-scrollbar">
              {data?.doctors.map((doctor, index) => (
                <div
                  onClick={() => navigate(`/app/home/doctors-info/${doctor.id}`)}
                  key={`doctor-list-${index}`}
                  className=" cursor-pointer flex flex-col min-w-[78.75px] items-center gap-[6px]  whitespace-nowrap"
                >
                  <img
                    src={`${process.env.REACT_APP_IMG_ENDPOINT}/${doctor.image}`}
                    alt="doctor-picture"
                    className="w-[105px] h-[98px] rounded-[11.25px] object-cover"
                    loading="lazy"
                  />
                  <div className="flex flex-col items-center">
                    <span className="text-[15px] text-N0">
                      {transLn(doctor.name)} {transLn(doctor.position)}
                    </span>
                    <span className="text-sm text-N4">{transLn(doctor.department)}</span>
                  </div>
                </div>
              ))}
            </main>
          </div>
          <div className="mt-6">
            <header className="flex justify-start w-full gap-4 text-N0">
              <button
                onClick={() => setCurrentTab(true)}
                className={`font-semibold ${currentTab ? 'border-b border-N0' : 'text-N4'}`}
              >
                {t('categoryInfo.effectOverview')}
              </button>
              <button
                onClick={() => setCurrentTab(false)}
                className={`font-semibold ${!currentTab ? 'border-b border-N0' : 'text-N4'}`}
              >
                {t('categoryInfo.recommendedUse')}
              </button>
            </header>
            <main className="w-full h-full mt-6 overflow-y-auto no-scrollbar text-N0 ">
              {currentTab
                ? data?.effectIntroduction
                  ? transLn(data.effectIntroduction)
                      .split('\n')
                      .map((item) => {
                        return <p>{item}</p>;
                      })
                  : ''
                : data?.effectiveUsage
                  ? transLn(data.effectiveUsage)
                      .split('\n')
                      .map((item) => {
                        return <p>{item}</p>;
                      })
                  : ''}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
