import { useContext } from 'react';
import KakaoLogin from 'react-kakao-login';
import { useNavigate } from 'react-router-dom';
import { KaKaoTalkLogin } from '../../assets';
import AuthAxios from '../../axios';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';
import { setItem, USERINFO, USERTOKEN } from '../../utils/localStorage';
export default function KakaoLoginBtn({
  afterSocialLoginSuccess,
  dbinsurance = false,
}: {
  afterSocialLoginSuccess: (params: {
    type: string;
    socialId: string;
    email: string;
    ageRange?: string;
    birthday?: string;
    birthdayType?: 'LUNAR' | 'SOLAR';
    birthYear?: string;
    gender?: 'male' | 'female';
    name?: 'string';
    phoneNumber?: string;
  }) => void;
  dbinsurance?: boolean;
}) {
  const { setLoginSucssess } = useContext(AuthStateContext);
  const { handleModal } = useContext(ModalContext);
  const navigate = useNavigate();
  return (
    <KakaoLogin
      token={`${process.env.REACT_APP_KAKAO_CLIENT_ID}`}
      onSuccess={(result: any) => {
        // console.log(result.profile);
        // console.log(result.profile.id); //소셜아이디
        // console.log(result.profile.kakao_account.email); //이메일

        console.log(result);

        afterSocialLoginSuccess({
          type: 'kakao',
          socialId: result.profile.id,
          email: result.profile.kakao_account.email,
          ageRange: result.profile.kakao_account.age_range,
          birthday: result.profile.kakao_account.birthday,
          birthdayType: result.profile.kakao_account.birthday_type,
          birthYear: result.profile.kakao_account.birthyear,
          gender: result.profile.kakao_account.gender,
          name: result.profile.kakao_account.name,
          phoneNumber: result.profile.kakao_account.phone_number,
        });
        // AuthAxios.post(`/auth/social`, {
        //   type: "kakao",
        //   socialId: result.profile.id,
        //   email: result.profile.kakao_account.email,
        // })
        //   .then((resolve) => {
        //     const { user, accessToken, refreshToken } = resolve.data;

        //     setItem(USERINFO, user);
        //     setItem(USERTOKEN, { accessToken, refreshToken });
        //     setLoginSucssess(user);
        //     if (user.termsAgreed) {
        //       if (user.gender && user.birthday) {
        //         navigate("/app/home", { replace: true });
        //       } else {
        //         navigate("/auth/basicInfo", { replace: true });
        //       }
        //     } else {
        //       navigate("/auth/agrement", { replace: true });
        //     }
        //   })
        //   .catch((error) => {
        //     // console.log(error);
        //     handleModal(
        //       <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
        //         <div>{error.response.data.message ?? ""}</div>
        //         <button
        //           className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
        //           onClick={() => handleModal()}
        //         >
        //           확인
        //         </button>
        //       </div>
        //     );
        //   });
      }}
      onFail={(error) => {
        console.log(error);
      }}
      render={({ onClick }) => {
        const iconSize = dbinsurance ? '3rem' : '2rem';
        const bgSize = dbinsurance ? '4rem' : '3rem';
        return (
          <button
            onClick={onClick}
            className="flex items-center justify-center w-12 h-12 bg-yellow-400 rounded-lg"
            style={{ width: bgSize, height: bgSize }}
          >
            <KaKaoTalkLogin width={iconSize} height={iconSize} />
          </button>
        );
      }}
    />
  );
}
