import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Left_arrow from '../../assets/images/Btn_Back.png';
import SurveyStepper from './Stepper';
import surveyEng from '../../locales/en/survey.json';
import surveyKo from '../../locales/ko/survey.json';
import CustomRadioButton from './CustomRadioButton';
import { getItem, LocalLanguage } from '../../utils/localStorage';
import Swal from 'sweetalert2';

export const SurveyQuestions: React.FC = () => {
  /** Initialization */
  const navigate = useNavigate();
  const language = getItem(LocalLanguage);
  const survey = language === 'en' ? surveyEng : surveyKo;
  const [currentStep, setCurrentStep] = useState(1);
  const [answers, setAnswers] = useState<(number | number[])[]>(Array(survey.length).fill(-1));

  /** Handlers */
  const handleAnswerChange = (answer: number | number[]) => {
    const newAnswers = [...answers];
    newAnswers[currentStep - 1] = answer;
    setAnswers(newAnswers);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const valueAsNumber = parseInt(value, 10);
    const currentQuestion = survey[currentStep - 1];

    if (currentQuestion.multiple) {
      const currentAnswers = Array.isArray(answers[currentStep - 1]) ? (answers[currentStep - 1] as number[]) : [];
      const updatedAnswers = checked
        ? [...currentAnswers, valueAsNumber]
        : currentAnswers.filter((answer) => answer !== valueAsNumber);

      handleAnswerChange(updatedAnswers);
    } else {
      handleAnswerChange(valueAsNumber);
    }
  };

  const goToNextStep = async () => {
    const currentAnswer = answers[currentStep - 1];
    const isAnswerValid = Array.isArray(currentAnswer) ? currentAnswer.length > 0 : currentAnswer !== -1;

    if (isAnswerValid) {
      if (currentStep === survey.length) {
        const data = await ReFormData(answers);
        navigate('/ai-survey/survey-result', {
          state: { data: JSON.stringify(data) },
        });
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      // alert(language === 'ko' ? '답변을 선택해주세요!' : 'Please choose your answer!');
      const isMobile = window.innerWidth <= 640;
      Swal.fire({
        width: isMobile ? 300 : 320,
        title: language === 'ko' ? '답변을 선택해주세요!' : 'Please choose your answer!',
        icon: 'warning',
        confirmButtonText: 'OK',
        customClass: {
          popup: 'bg-white shadow-lg rounded-lg p-6 max-w-sm w-full',
          title: 'text-gray-800 font-bold text-xl',
          confirmButton: 'bg-N12 text-white px-4 py-2 rounded',
          icon: 'w-14 h-14 bg-gray',
        },
        buttonsStyling: false,
        heightAuto: false,
      });
      const warningIcon = document.querySelector('.swal2-icon.swal2-warning') as HTMLElement;
      if (warningIcon) {
        warningIcon.style.color = 'gray';
        warningIcon.style.border = '4px solid gray';
      }
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('====================================');
    console.log(currentStep > survey.length);
    console.log('🚀 ~ handleSubmit ~ survey.length:', survey.length);
    console.log('🚀 ~ handleSubmit ~ currentStep:', currentStep);
    console.log('====================================');
    if (currentStep > survey.length) {
      const data = await ReFormData(answers);
      navigate('/ai-survey/survey-result', {
        state: { data: JSON.stringify(data) },
      });
    }
  };

  const ReFormData = async (surveyData: (number | number[])[]) => {
    const data = language === 'en' ? surveyEng : surveyKo;
    const deleteInputList = ['UsedServices'];
    const surveyFilteredList = surveyData
      .filter((answer, index) => !deleteInputList.includes(data[index].code))
      .map((answer, index) => ({
        question: data[index].code,
        answer: answer,
      }));
    const predictInput = Object.fromEntries(surveyFilteredList.map((e) => [e.question, e.answer]));
    console.log('====================================');
    console.log('🚀 ~ analyze ~ predictInput:', predictInput);
    console.log('====================================');

    return predictInput;
  };

  const currentQuestion = survey[currentStep - 1];
  if (!currentQuestion) {
    return null;
  }

  console.log({ language });

  return (
    <div className="flex flex-col justify-center w-full h-full bg-gray-400">
      <div className="flex flex-col align-center w-full md:w-[50rem] h-full m-auto bg-white px-[2rem] md:px-[10rem] overflow-y-scroll no-scrollbar md:overflow-hidden">
        <img
          src={Left_arrow}
          alt="Back"
          className="w-[12px] h-[20px] mt-5 md:hidden cursor-pointer lg:mb-20"
          onClick={() => navigate('/ai-survey')}
        />
        <h1 className="mt-12 md:mt-[9.375rem] mb-2" style={{ color: '#788296' }}>
          {currentStep}/{survey.length}
        </h1>
        <div className="w-full mb-4 md:mb-0">
          <SurveyStepper currentStep={currentStep} totalSteps={survey.length} />
        </div>
        <h1 className="font-bold w-full md:mt-8 text-xl md:text-2xl mb-5" style={{ color: '#191919' }}>
          {currentQuestion.question}
        </h1>
        <form className="flex flex-col justify-between h-full gap-y-3" action="POST" onSubmit={handleSubmit}>
          {currentQuestion.answers?.map((answer, index) => (
            <CustomRadioButton
              key={index}
              id={`option-${index}`}
              name={`question-${currentStep}`}
              value={index}
              label={answer.label}
              checked={
                currentQuestion.multiple
                  ? Array.isArray(answers[currentStep - 1]) && (answers[currentStep - 1] as number[]).includes(index)
                  : answers[currentStep - 1] === index
              }
              onChange={handleCheckboxChange}
              multiple={currentQuestion.multiple}
            />
          ))}
          <div className="flex flex-row mt-auto md:mx-auto mb-[10.625rem]">
            <button
              style={{ background: 'lightGray' }}
              className="w-[160px] md:w-[200px] text-N11 h-[50px]   mr-7 text-xl font-bold rounded-lg"
              onClick={goToPreviousStep}
              disabled={currentStep === 1}
            >
              이전으로
            </button>
            <button
              className="w-[160px] md:w-[200px] h-[50px]  md:m-0 bg-N12 text-xl text-white font-bold rounded-lg"
              onClick={goToNextStep}
            >
              {currentStep === survey.length ? '완료' : '다음으로'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
