import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CancelSVG } from '../../assets';
import { ModalContext } from '../../contexts/ModalContext';
import IconButton from '../IconButton';
import TimePicker from '../utils/TimePicker';

interface ITimerModalProps {
  handleSetTimer: (value: number, fadeOut: boolean) => void;
}
export default function TimerModal(props: ITimerModalProps) {
  const { handleSetTimer } = props;
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const [hourIndex, setHourIndex] = useState(12);
  const [minuteIndex, setMinuteIndex] = useState(59);
  const [fadeOutEnabled, setFadeOutEnabled] = useState(false);

  const handleSubmit = () => {
    let convertSecond = hourIndex * 3600 + minuteIndex * 60;
    handleSetTimer(convertSecond, fadeOutEnabled);

    handleModal();
  };

  return (
    <div className="text-white rounded-lg relative flex flex-col items-center w-[300px]  h-auto p-4 bg-N10 md:bg-[#212226] ">
      <IconButton className="absolute top-3 right-3" onClick={() => handleModal()}>
        <CancelSVG width={14} height={14} />
      </IconButton>
      <header className="my-4">{t('timerModal.title')}</header>
      <div>
        <div className="relative flex flex-col items-center justify-center w-full h-auto">
          <TimePicker setHourIndex={setHourIndex} setMinuteIndex={setMinuteIndex} />
          <button
            // type="submit"
            onClick={handleSubmit}
            className="font-semibold w-[250px] h-[40px] max-sm:w-[143px] max-sm:h-[43px] rounded-lg bg-gradient-to-r from-[#63ACED] to-[#2E239D]"
          >
            {t('timerModal.setTimer')}
          </button>
          <label htmlFor="calm_down_sound" className="flex gap-2 mt-4">
            <input
              type="checkbox"
              id="calm_down_sound"
              checked={fadeOutEnabled}
              onChange={() => setFadeOutEnabled(!fadeOutEnabled)}
            />
            <span className="text-sm text-N0">{t('timer.volumeFadeOut')}</span>
          </label>
        </div>
      </div>
    </div>
  );
}
