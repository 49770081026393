import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { Product, ResponseType } from '../types';
import AuthAxios from '../axios';
// Renders errors or successfull transactions on the screen.
// function Message({ content }) {
//   return <p>{content}</p>;
// }

function App() {
  const initialOptions = {
    clientId: 'AWczQVGfn515F5ztaiiRJgqX7G_HODFl0kGQBqplYf3R0LK-_bkZ0R1jmu1nhSs3AgQoMjjpmWHP8Cuu',
    'enable-funding': 'paylater',
    'disable-funding': 'card',
    'data-sdk-integration-source': 'integrationbuilder_sc',
    vault: 'true',
    intent: 'subscription',
  };

  const [message, setMessage] = useState('');
  const location = useLocation();
  const { state } = location;
  const product = state?.product as Product;

  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: 'rect',
            layout: 'vertical',
          }}
          createSubscription={async () => {
            console.log('product', product);
            try {
              const tmpData: any = await AuthAxios.post(
                `/PaymentMethod/paypal`,
                { price: product.price, userAction: 'SUBSCRIBE_NOW' } //JSON.stringify({ price: product.price, userAction: "SUBSCRIBE_NOW" }),
              )
                .then((res) => {
                  return res;
                  //setBookMarkTracks(newPlayList);
                })
                .catch(() => {
                  //console.log(error);
                })
                .finally(() => {
                  //setSelectedTracks([]);
                });
              console.log('tmpData', tmpData.data);
              const data = tmpData.data;

              if (data?.id) {
                console.log('data', data);

                setMessage(`Successful subscription...`);
                return data.id;
              } else {
                console.error({ callback: 'createSubscription', serverResponse: data }, JSON.stringify(data, null, 2));
                // (Optional) The following hides the button container and shows a message about why checkout can't be initiated
                const errorDetail = data?.details?.[0];
                setMessage(
                  `Could not initiate PayPal Subscription...<br><br>${
                    errorDetail?.issue || ''
                  } ${errorDetail?.description || data?.message || ''} ` + (data?.debug_id ? `(${data.debug_id})` : '')
                  //   { hideButtons: true },
                );
              }
            } catch (error) {
              console.error(error);
              setMessage(`Could not initiate PayPal Subscription...${error}`);
            }
          }}
          onApprove={async (data: any, actions: any) => {
            /*
              No need to activate manually since SUBSCRIBE_NOW is being used.
              Learn how to handle other user actions from our docs:
              https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_create
            */
            console.log('onApprove', data);
            if (data.orderID) {
              setMessage(
                `You have successfully subscribed to the plan. Your subscription id is: ${data.subscriptionID}`
              );
            } else {
              setMessage(`Failed to activate the subscription: ${data.subscriptionID}`);
            }
          }}
        />
      </PayPalScriptProvider>
      {/* <Message content={message} /> */}
    </div>
  );
}

export default App;
